/* tslint:disable */
/* eslint-disable */
/**
 * PowerDIS Private API
 *  
 *
 * The version of the OpenAPI document: p10-api@f4846dee03d31522496977956b959c338b348c4c
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * @type BusySharedProjectPost200Response
 * @export
 */
export type BusySharedProjectPost200Response = BusySharedProjectPost200ResponseOneOf | ErrorResponse;

/**
 * 
 * @export
 * @interface BusySharedProjectPost200ResponseOneOf
 */
export interface BusySharedProjectPost200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof BusySharedProjectPost200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {BusySharedProjectPost200ResponseOneOfData}
     * @memberof BusySharedProjectPost200ResponseOneOf
     */
    'data'?: BusySharedProjectPost200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface BusySharedProjectPost200ResponseOneOfData
 */
export interface BusySharedProjectPost200ResponseOneOfData {
    /**
     * If locked is true, the user currently in use of the project, cannot be the passed user_id.
     * @type {string}
     * @memberof BusySharedProjectPost200ResponseOneOfData
     */
    'user'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusySharedProjectPost200ResponseOneOfData
     */
    'locked'?: boolean;
}
/**
 * 
 * @export
 * @interface BusySharedProjectPostRequest
 */
export interface BusySharedProjectPostRequest {
    /**
     * 
     * @type {number}
     * @memberof BusySharedProjectPostRequest
     */
    'project_id': number;
    /**
     * 
     * @type {number}
     * @memberof BusySharedProjectPostRequest
     */
    'user_id': number;
}
/**
 * @type BusySharedStudyPost200Response
 * @export
 */
export type BusySharedStudyPost200Response = BusySharedStudyPost200ResponseOneOf | ErrorResponse;

/**
 * 
 * @export
 * @interface BusySharedStudyPost200ResponseOneOf
 */
export interface BusySharedStudyPost200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof BusySharedStudyPost200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {BusySharedStudyPost200ResponseOneOfData}
     * @memberof BusySharedStudyPost200ResponseOneOf
     */
    'data'?: BusySharedStudyPost200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface BusySharedStudyPost200ResponseOneOfData
 */
export interface BusySharedStudyPost200ResponseOneOfData {
    /**
     * - If locked is true, the user currently in use of the study, cannot be the passed user_id. - If locked is false, passed user_id or user with the last activity if different. 
     * @type {string}
     * @memberof BusySharedStudyPost200ResponseOneOfData
     */
    'user'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BusySharedStudyPost200ResponseOneOfData
     */
    'locked'?: boolean;
}
/**
 * 
 * @export
 * @interface BusySharedStudyPostRequest
 */
export interface BusySharedStudyPostRequest {
    /**
     * 
     * @type {number}
     * @memberof BusySharedStudyPostRequest
     */
    'study_id': number;
    /**
     * The user currently connected
     * @type {number}
     * @memberof BusySharedStudyPostRequest
     */
    'user_id': number;
}
/**
 * 
 * @export
 * @interface ErrorCodeResponse
 */
export interface ErrorCodeResponse {
    /**
     * code:   * 1 - User.DoesNotExist   * 2 - Project.DoesNotExist 
     * @type {number}
     * @memberof ErrorCodeResponse
     */
    'code'?: ErrorCodeResponseCodeEnum;
    /**
     * Error message
     * @type {string}
     * @memberof ErrorCodeResponse
     */
    'message'?: string;
}

export const ErrorCodeResponseCodeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ErrorCodeResponseCodeEnum = typeof ErrorCodeResponseCodeEnum[keyof typeof ErrorCodeResponseCodeEnum];

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ErrorResponse
     */
    'status'?: boolean;
    /**
     * Error message
     * @type {string}
     * @memberof ErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface FeaturesInner
 */
export interface FeaturesInner {
    /**
     * 
     * @type {string}
     * @memberof FeaturesInner
     */
    'type': FeaturesInnerTypeEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof FeaturesInner
     */
    'properties': { [key: string]: any; };
    /**
     * 
     * @type {Geometry}
     * @memberof FeaturesInner
     */
    'geometry': Geometry;
}

export const FeaturesInnerTypeEnum = {
    Feature: 'Feature'
} as const;

export type FeaturesInnerTypeEnum = typeof FeaturesInnerTypeEnum[keyof typeof FeaturesInnerTypeEnum];

/**
 * 
 * @export
 * @interface Geojson
 */
export interface Geojson {
    /**
     * 
     * @type {string}
     * @memberof Geojson
     */
    'type': GeojsonTypeEnum;
    /**
     * 
     * @type {GeojsonCrs}
     * @memberof Geojson
     */
    'crs': GeojsonCrs;
    /**
     * 
     * @type {Array<FeaturesInner>}
     * @memberof Geojson
     */
    'features': Array<FeaturesInner>;
}

export const GeojsonTypeEnum = {
    FeatureCollection: 'FeatureCollection'
} as const;

export type GeojsonTypeEnum = typeof GeojsonTypeEnum[keyof typeof GeojsonTypeEnum];

/**
 * 
 * @export
 * @interface GeojsonCrs
 */
export interface GeojsonCrs {
    /**
     * 
     * @type {GeojsonCrsProperties}
     * @memberof GeojsonCrs
     */
    'properties'?: GeojsonCrsProperties;
    /**
     * 
     * @type {string}
     * @memberof GeojsonCrs
     */
    'type'?: GeojsonCrsTypeEnum;
}

export const GeojsonCrsTypeEnum = {
    Name: 'name'
} as const;

export type GeojsonCrsTypeEnum = typeof GeojsonCrsTypeEnum[keyof typeof GeojsonCrsTypeEnum];

/**
 * 
 * @export
 * @interface GeojsonCrsProperties
 */
export interface GeojsonCrsProperties {
    /**
     * 
     * @type {string}
     * @memberof GeojsonCrsProperties
     */
    'name'?: GeojsonCrsPropertiesNameEnum;
}

export const GeojsonCrsPropertiesNameEnum = {
    Epsg4326: 'epsg:4326'
} as const;

export type GeojsonCrsPropertiesNameEnum = typeof GeojsonCrsPropertiesNameEnum[keyof typeof GeojsonCrsPropertiesNameEnum];

/**
 * @type Geometry
 * @export
 */
export type Geometry = LineString | MultiLineString | MultiPoint | MultiPolygon | Point | Polygon;

/**
 * GeoJSON LineString geometry
 * @export
 * @interface LineString
 */
export interface LineString {
    /**
     * 
     * @type {string}
     * @memberof LineString
     */
    'type': LineStringTypeEnum;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof LineString
     */
    'coordinates': Array<Array<number>>;
}

export const LineStringTypeEnum = {
    LineString: 'LineString'
} as const;

export type LineStringTypeEnum = typeof LineStringTypeEnum[keyof typeof LineStringTypeEnum];

/**
 * 
 * @export
 * @interface LoadCurve
 */
export interface LoadCurve {
    /**
     * 
     * @type {string}
     * @memberof LoadCurve
     */
    '_key': string;
    /**
     * 
     * @type {string}
     * @memberof LoadCurve
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LoadCurve
     */
    'unit': LoadCurveUnitEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LoadCurve
     */
    'default': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoadCurve
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof LoadCurve
     */
    'mode': LoadCurveModeEnum;
    /**
     * 
     * @type {string}
     * @memberof LoadCurve
     */
    'extension': string;
    /**
     * 
     * @type {string}
     * @memberof LoadCurve
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LoadCurve
     */
    'creation_time': string;
}

export const LoadCurveUnitEnum = {
    W: 'W',
    WM: 'W/m²'
} as const;

export type LoadCurveUnitEnum = typeof LoadCurveUnitEnum[keyof typeof LoadCurveUnitEnum];
export const LoadCurveModeEnum = {
    Heating: 'Heating',
    Cooling: 'Cooling',
    Dhw: 'DHW',
    Electricity: 'Electricity'
} as const;

export type LoadCurveModeEnum = typeof LoadCurveModeEnum[keyof typeof LoadCurveModeEnum];

/**
 * 
 * @export
 * @interface LoadCurvesGet200Response
 */
export interface LoadCurvesGet200Response {
    /**
     * 
     * @type {Array<LoadCurve>}
     * @memberof LoadCurvesGet200Response
     */
    'user'?: Array<LoadCurve>;
    /**
     * 
     * @type {Array<LoadCurve>}
     * @memberof LoadCurvesGet200Response
     */
    'default'?: Array<LoadCurve>;
    /**
     * 
     * @type {Array<LoadCurve>}
     * @memberof LoadCurvesGet200Response
     */
    'project'?: Array<LoadCurve>;
}
/**
 * GeoJSON MultiLineString geometry
 * @export
 * @interface MultiLineString
 */
export interface MultiLineString {
    /**
     * 
     * @type {string}
     * @memberof MultiLineString
     */
    'type': MultiLineStringTypeEnum;
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof MultiLineString
     */
    'coordinates': Array<Array<Array<number>>>;
}

export const MultiLineStringTypeEnum = {
    MultiLineString: 'MultiLineString'
} as const;

export type MultiLineStringTypeEnum = typeof MultiLineStringTypeEnum[keyof typeof MultiLineStringTypeEnum];

/**
 * GeoJSON MultiPoint geometry
 * @export
 * @interface MultiPoint
 */
export interface MultiPoint {
    /**
     * 
     * @type {string}
     * @memberof MultiPoint
     */
    'type': MultiPointTypeEnum;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof MultiPoint
     */
    'coordinates': Array<Array<number>>;
}

export const MultiPointTypeEnum = {
    MultiPoint: 'MultiPoint'
} as const;

export type MultiPointTypeEnum = typeof MultiPointTypeEnum[keyof typeof MultiPointTypeEnum];

/**
 * GeoJSON MultiPolygon geometry
 * @export
 * @interface MultiPolygon
 */
export interface MultiPolygon {
    /**
     * 
     * @type {string}
     * @memberof MultiPolygon
     */
    'type': MultiPolygonTypeEnum;
    /**
     * 
     * @type {Array<Array<Array<Array<number>>>>}
     * @memberof MultiPolygon
     */
    'coordinates': Array<Array<Array<Array<number>>>>;
}

export const MultiPolygonTypeEnum = {
    MultiPolygon: 'MultiPolygon'
} as const;

export type MultiPolygonTypeEnum = typeof MultiPolygonTypeEnum[keyof typeof MultiPolygonTypeEnum];

/**
 * GeoJSON Point geometry
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    'type': PointTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof Point
     */
    'coordinates': Array<number>;
}

export const PointTypeEnum = {
    Point: 'Point'
} as const;

export type PointTypeEnum = typeof PointTypeEnum[keyof typeof PointTypeEnum];

/**
 * GeoJSON Polygon geometry
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {string}
     * @memberof Polygon
     */
    'type': PolygonTypeEnum;
    /**
     * 
     * @type {Array<Array<Array<number>>>}
     * @memberof Polygon
     */
    'coordinates': Array<Array<Array<number>>>;
}

export const PolygonTypeEnum = {
    Polygon: 'Polygon'
} as const;

export type PolygonTypeEnum = typeof PolygonTypeEnum[keyof typeof PolygonTypeEnum];

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'id'?: number;
    /**
     * Do not use. Internal ID of the users group corresponding to the project.
     * @type {number}
     * @memberof Project
     */
    'group'?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'description'?: string;
    /**
     * Free text. Should be a float, but no guarantee.
     * @type {string}
     * @memberof Project
     */
    'latitude'?: string;
    /**
     * Free text. Should be a float, but no guarantee.
     * @type {string}
     * @memberof Project
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'creation_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'last_save_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'address'?: string;
}
/**
 * @type ProjectPost200Response
 * @export
 */
export type ProjectPost200Response = ErrorResponse | ProjectPost200ResponseOneOf;

/**
 * 
 * @export
 * @interface ProjectPost200ResponseOneOf
 */
export interface ProjectPost200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectPost200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {Project}
     * @memberof ProjectPost200ResponseOneOf
     */
    'data'?: Project;
}
/**
 * 
 * @export
 * @interface ProjectPostRequest
 */
export interface ProjectPostRequest {
    /**
     * Creator of the project, added with the Owner profile.
     * @type {number}
     * @memberof ProjectPostRequest
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'description': string;
    /**
     * Free text, should be a float, but not enforced.
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'latitude': string;
    /**
     * Free text, should be a float, but not enforced.
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'longitude': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectPostRequest
     */
    'address': string;
}
/**
 * @type ProjectProjectIdDeleteDelete200Response
 * @export
 */
export type ProjectProjectIdDeleteDelete200Response = ErrorResponse | SimpleStatusResponse;

/**
 * 
 * @export
 * @interface ProjectProjectIdDuplicatePostRequest
 */
export interface ProjectProjectIdDuplicatePostRequest {
    /**
     * This user becomes the Owner of the new project. It is not required to belong to the original project. Users of the original project are also added. 
     * @type {number}
     * @memberof ProjectProjectIdDuplicatePostRequest
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdDuplicatePostRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdDuplicatePostRequest
     */
    'description': string;
    /**
     * Free text, should be a float, but not enforced.
     * @type {string}
     * @memberof ProjectProjectIdDuplicatePostRequest
     */
    'latitude': string;
    /**
     * Free text, should be a float, but not enforced.
     * @type {string}
     * @memberof ProjectProjectIdDuplicatePostRequest
     */
    'longitude': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdDuplicatePostRequest
     */
    'address': string;
    /**
     * 
     * @type {ResultsToDuplicate}
     * @memberof ProjectProjectIdDuplicatePostRequest
     */
    'results': ResultsToDuplicate;
}


/**
 * @type ProjectProjectIdGet200Response
 * @export
 */
export type ProjectProjectIdGet200Response = ErrorResponse | ProjectProjectIdGet200ResponseOneOf;

/**
 * 
 * @export
 * @interface ProjectProjectIdGet200ResponseOneOf
 */
export interface ProjectProjectIdGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectProjectIdGet200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {ProjectWithUsers}
     * @memberof ProjectProjectIdGet200ResponseOneOf
     */
    'data'?: ProjectWithUsers;
}
/**
 * @type ProjectProjectIdStudiesGet200Response
 * @export
 */
export type ProjectProjectIdStudiesGet200Response = ErrorResponse | ProjectProjectIdStudiesGet200ResponseOneOf;

/**
 * 
 * @export
 * @interface ProjectProjectIdStudiesGet200ResponseOneOf
 */
export interface ProjectProjectIdStudiesGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {Array<ProjectProjectIdStudiesGet200ResponseOneOfDataInner>}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOf
     */
    'data'?: Array<ProjectProjectIdStudiesGet200ResponseOneOfDataInner>;
}
/**
 * 
 * @export
 * @interface ProjectProjectIdStudiesGet200ResponseOneOfDataInner
 */
export interface ProjectProjectIdStudiesGet200ResponseOneOfDataInner {
    /**
     * 
     * @type {number}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'project'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'description'?: string;
    /**
     * ArangoDB document ID for the study, in the `Study` collection
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'file'?: string;
    /**
     * The study imported layer image coordinates for buildings, as a JSON string.
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'building_image_coordinates'?: string | null;
    /**
     * The study imported layer image coordinates for networks, as a JSON string.
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'network_image_coordinates'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'creation_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'last_save_time'?: string;
    /**
     * ArangoDB document ID for the study\'s weather file, in the `WeatherFiles` collection
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'climate_file'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'background_map'?: ProjectProjectIdStudiesGet200ResponseOneOfDataInnerBackgroundMapEnum;
    /**
     * 
     * @type {ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInner
     */
    'simulation'?: ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation;
}

export const ProjectProjectIdStudiesGet200ResponseOneOfDataInnerBackgroundMapEnum = {
    Ign: 'ign',
    Osm: 'osm'
} as const;

export type ProjectProjectIdStudiesGet200ResponseOneOfDataInnerBackgroundMapEnum = typeof ProjectProjectIdStudiesGet200ResponseOneOfDataInnerBackgroundMapEnum[keyof typeof ProjectProjectIdStudiesGet200ResponseOneOfDataInnerBackgroundMapEnum];

/**
 * 
 * @export
 * @interface ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation
 */
export interface ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation {
    /**
     * 
     * @type {SimulationInfos}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation
     */
    'demand'?: SimulationInfos;
    /**
     * 
     * @type {SimulationInfos}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation
     */
    'sizing'?: SimulationInfos;
    /**
     * 
     * @type {SimulationInfos}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation
     */
    'systems'?: SimulationInfos;
}
/**
 * 
 * @export
 * @interface ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf
 */
export interface ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf {
    /**
     * 
     * @type {SimulationInfos}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf
     */
    'demand'?: SimulationInfos;
}
/**
 * 
 * @export
 * @interface ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf1
 */
export interface ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf1 {
    /**
     * 
     * @type {SimulationInfos}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf1
     */
    'sizing'?: SimulationInfos;
}
/**
 * 
 * @export
 * @interface ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf2
 */
export interface ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf2 {
    /**
     * 
     * @type {SimulationInfos}
     * @memberof ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulationAnyOf2
     */
    'systems'?: SimulationInfos;
}
/**
 * 
 * @export
 * @interface ProjectProjectIdUpdatePutRequest
 */
export interface ProjectProjectIdUpdatePutRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdUpdatePutRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdUpdatePutRequest
     */
    'description': string;
    /**
     * Free text, should be a float, but not enforced.
     * @type {string}
     * @memberof ProjectProjectIdUpdatePutRequest
     */
    'latitude': string;
    /**
     * Free text, should be a float, but not enforced.
     * @type {string}
     * @memberof ProjectProjectIdUpdatePutRequest
     */
    'longitude': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectProjectIdUpdatePutRequest
     */
    'address': string;
}
/**
 * 
 * @export
 * @interface ProjectWithUsers
 */
export interface ProjectWithUsers {
    /**
     * 
     * @type {number}
     * @memberof ProjectWithUsers
     */
    'id'?: number;
    /**
     * Do not use. Internal ID of the users group corresponding to the project.
     * @type {number}
     * @memberof ProjectWithUsers
     */
    'group'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithUsers
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithUsers
     */
    'description'?: string;
    /**
     * Free text. Should be a float, but no guarantee.
     * @type {string}
     * @memberof ProjectWithUsers
     */
    'latitude'?: string;
    /**
     * Free text. Should be a float, but no guarantee.
     * @type {string}
     * @memberof ProjectWithUsers
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithUsers
     */
    'creation_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithUsers
     */
    'last_save_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithUsers
     */
    'address'?: string;
    /**
     * `true` if other users have access to the project
     * @type {boolean}
     * @memberof ProjectWithUsers
     */
    'is_shared'?: boolean;
    /**
     * All users who have access to the project
     * @type {Array<UserInProject>}
     * @memberof ProjectWithUsers
     */
    'users'?: Array<UserInProject>;
}
/**
 * Not required, defaults to `[]`.
 * @export
 * @enum {string}
 */

export const ResultsToDuplicate = {
    RightSquareBracket: '[]',
    Demand: '[\"demand\"]',
    Demandsystems: '[\"demand\",\"systems\"]'
} as const;

export type ResultsToDuplicate = typeof ResultsToDuplicate[keyof typeof ResultsToDuplicate];


/**
 * 
 * @export
 * @interface Scenario
 */
export interface Scenario {
    /**
     * 
     * @type {string}
     * @memberof Scenario
     */
    '_key': string;
    /**
     * 
     * @type {string}
     * @memberof Scenario
     */
    'scenario_name': string;
    /**
     * 
     * @type {string}
     * @memberof Scenario
     */
    'unit': ScenarioUnitEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Scenario
     */
    'default': boolean;
    /**
     * 
     * @type {string}
     * @memberof Scenario
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof Scenario
     */
    'code_typology': string;
    /**
     * 
     * @type {string}
     * @memberof Scenario
     */
    'display_property': string;
    /**
     * 
     * @type {number}
     * @memberof Scenario
     */
    'value_max': number;
    /**
     * 
     * @type {string}
     * @memberof Scenario
     */
    'category': ScenarioCategoryEnum;
}

export const ScenarioUnitEnum = {
    C: '°C',
    WM2: 'W/m2',
    LHM2: 'L/h/m2',
    VolM3: 'vol/m3'
} as const;

export type ScenarioUnitEnum = typeof ScenarioUnitEnum[keyof typeof ScenarioUnitEnum];
export const ScenarioCategoryEnum = {
    HeatingSetPoint: 'heating_set_point',
    CoolingSetPoint: 'cooling_set_point',
    AirChangeRateSetPoint: 'air_change_rate_set_point',
    InternalGainMetabolism: 'internal_gain_metabolism',
    WaterFlow: 'water_flow',
    ElectricLoad: 'electric_load'
} as const;

export type ScenarioCategoryEnum = typeof ScenarioCategoryEnum[keyof typeof ScenarioCategoryEnum];

/**
 * @type ScenarioGetAllGet200Response
 * @export
 */
export type ScenarioGetAllGet200Response = ErrorResponse | ScenarioGetAllGet200ResponseOneOf;

/**
 * 
 * @export
 * @interface ScenarioGetAllGet200ResponseOneOf
 */
export interface ScenarioGetAllGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof ScenarioGetAllGet200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {ScenarioGetAllGet200ResponseOneOfData}
     * @memberof ScenarioGetAllGet200ResponseOneOf
     */
    'data'?: ScenarioGetAllGet200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface ScenarioGetAllGet200ResponseOneOfData
 */
export interface ScenarioGetAllGet200ResponseOneOfData {
    /**
     * 
     * @type {Array<Scenario>}
     * @memberof ScenarioGetAllGet200ResponseOneOfData
     */
    'user': Array<Scenario>;
    /**
     * 
     * @type {Array<Scenario>}
     * @memberof ScenarioGetAllGet200ResponseOneOfData
     */
    'default': Array<Scenario>;
    /**
     * 
     * @type {Array<Scenario>}
     * @memberof ScenarioGetAllGet200ResponseOneOfData
     */
    'project'?: Array<Scenario>;
}
/**
 * 
 * @export
 * @interface SimpleStatusResponse
 */
export interface SimpleStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SimpleStatusResponse
     */
    'status'?: boolean;
}
/**
 * @type SimulationCreatePost200Response
 * @export
 */
export type SimulationCreatePost200Response = ErrorResponse | SimulationCreatePost200ResponseOneOf;

/**
 * 
 * @export
 * @interface SimulationCreatePost200ResponseOneOf
 */
export interface SimulationCreatePost200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof SimulationCreatePost200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {SimulationCreatePost200ResponseOneOfData}
     * @memberof SimulationCreatePost200ResponseOneOf
     */
    'data'?: SimulationCreatePost200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface SimulationCreatePost200ResponseOneOfData
 */
export interface SimulationCreatePost200ResponseOneOfData {
    /**
     * 
     * @type {number}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'project'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'description'?: string;
    /**
     * ArangoDB document ID for the study, in the `Study` collection
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'file'?: string;
    /**
     * The study imported layer image coordinates for buildings, as a JSON string.
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'building_image_coordinates'?: string | null;
    /**
     * The study imported layer image coordinates for networks, as a JSON string.
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'network_image_coordinates'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'creation_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'last_save_time'?: string;
    /**
     * ArangoDB document ID for the study\'s weather file, in the `WeatherFiles` collection
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'climate_file'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'background_map'?: SimulationCreatePost200ResponseOneOfDataBackgroundMapEnum;
    /**
     * ID of the user who launched the simulation.
     * @type {number}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'user'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'simulation_type'?: SimulationCreatePost200ResponseOneOfDataSimulationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'simulation_uuid'?: string;
    /**
     * The IP address of executing container.
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'simulation_ip'?: string;
    /**
     * ArangoDB document ID for the study results, in the `Results` collection.
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'result'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'status'?: SimulationCreatePost200ResponseOneOfDataStatusEnum;
    /**
     * The error level.
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'error'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'stage'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'timings'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'simulation_error_detail'?: string;
    /**
     * The emission_factors used in systems simulation.
     * @type {string}
     * @memberof SimulationCreatePost200ResponseOneOfData
     */
    'emission_factors'?: string;
}

export const SimulationCreatePost200ResponseOneOfDataBackgroundMapEnum = {
    Ign: 'ign',
    Osm: 'osm'
} as const;

export type SimulationCreatePost200ResponseOneOfDataBackgroundMapEnum = typeof SimulationCreatePost200ResponseOneOfDataBackgroundMapEnum[keyof typeof SimulationCreatePost200ResponseOneOfDataBackgroundMapEnum];
export const SimulationCreatePost200ResponseOneOfDataSimulationTypeEnum = {
    Demand: 'demand',
    Sizing: 'sizing',
    Systems: 'systems'
} as const;

export type SimulationCreatePost200ResponseOneOfDataSimulationTypeEnum = typeof SimulationCreatePost200ResponseOneOfDataSimulationTypeEnum[keyof typeof SimulationCreatePost200ResponseOneOfDataSimulationTypeEnum];
export const SimulationCreatePost200ResponseOneOfDataStatusEnum = {
    Checking: 'Checking',
    Pending: 'Pending',
    Launched: 'Launched',
    Running: 'Running',
    Done: 'Done',
    Failed: 'Failed',
    Aborted: 'Aborted'
} as const;

export type SimulationCreatePost200ResponseOneOfDataStatusEnum = typeof SimulationCreatePost200ResponseOneOfDataStatusEnum[keyof typeof SimulationCreatePost200ResponseOneOfDataStatusEnum];

/**
 * 
 * @export
 * @interface SimulationCreatePostRequest
 */
export interface SimulationCreatePostRequest {
    /**
     * Creator of the simulation.
     * @type {number}
     * @memberof SimulationCreatePostRequest
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof SimulationCreatePostRequest
     */
    'study_id': number;
    /**
     * 
     * @type {string}
     * @memberof SimulationCreatePostRequest
     */
    'simulation_type': SimulationCreatePostRequestSimulationTypeEnum;
    /**
     * The emission_factors used in systems simulation.
     * @type {string}
     * @memberof SimulationCreatePostRequest
     */
    'emission_factors'?: string;
}

export const SimulationCreatePostRequestSimulationTypeEnum = {
    Demand: 'demand',
    Sizing: 'sizing',
    Systems: 'systems'
} as const;

export type SimulationCreatePostRequestSimulationTypeEnum = typeof SimulationCreatePostRequestSimulationTypeEnum[keyof typeof SimulationCreatePostRequestSimulationTypeEnum];

/**
 * @type SimulationDeleteDelete200Response
 * @export
 */
export type SimulationDeleteDelete200Response = ErrorResponse | SimpleStatusResponse | SimulationDeleteDelete200ResponseOneOf;

/**
 * 
 * @export
 * @interface SimulationDeleteDelete200ResponseOneOf
 */
export interface SimulationDeleteDelete200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof SimulationDeleteDelete200ResponseOneOf
     */
    'is_valid'?: boolean;
    /**
     * 
     * @type {SimulationDeleteDelete200ResponseOneOfData}
     * @memberof SimulationDeleteDelete200ResponseOneOf
     */
    'data'?: SimulationDeleteDelete200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface SimulationDeleteDelete200ResponseOneOfData
 */
export interface SimulationDeleteDelete200ResponseOneOfData {
    /**
     * 
     * @type {number}
     * @memberof SimulationDeleteDelete200ResponseOneOfData
     */
    'code'?: number;
    /**
     * study id
     * @type {number}
     * @memberof SimulationDeleteDelete200ResponseOneOfData
     */
    'study'?: number;
    /**
     * simulation id
     * @type {number}
     * @memberof SimulationDeleteDelete200ResponseOneOfData
     */
    'status'?: number;
}
/**
 * 
 * @export
 * @interface SimulationDeleteDeleteRequest
 */
export interface SimulationDeleteDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SimulationDeleteDeleteRequest
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof SimulationDeleteDeleteRequest
     */
    'study_id': number;
    /**
     * 
     * @type {string}
     * @memberof SimulationDeleteDeleteRequest
     */
    'simulation_type': SimulationDeleteDeleteRequestSimulationTypeEnum;
}

export const SimulationDeleteDeleteRequestSimulationTypeEnum = {
    Demand: 'demand',
    Sizing: 'sizing',
    Systems: 'systems'
} as const;

export type SimulationDeleteDeleteRequestSimulationTypeEnum = typeof SimulationDeleteDeleteRequestSimulationTypeEnum[keyof typeof SimulationDeleteDeleteRequestSimulationTypeEnum];

/**
 * @type SimulationGet200Response
 * @export
 */
export type SimulationGet200Response = SimulationGet200ResponseOneOf;

/**
 * 
 * @export
 * @interface SimulationGet200ResponseOneOf
 */
export interface SimulationGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof SimulationGet200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {SimulationGet200ResponseOneOfData}
     * @memberof SimulationGet200ResponseOneOf
     */
    'data'?: SimulationGet200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface SimulationGet200ResponseOneOfData
 */
export interface SimulationGet200ResponseOneOfData {
    /**
     * 
     * @type {SimulationInfos}
     * @memberof SimulationGet200ResponseOneOfData
     */
    'demand'?: SimulationInfos;
    /**
     * 
     * @type {SimulationInfos}
     * @memberof SimulationGet200ResponseOneOfData
     */
    'sizing'?: SimulationInfos;
    /**
     * 
     * @type {SimulationInfos}
     * @memberof SimulationGet200ResponseOneOfData
     */
    'systems'?: SimulationInfos;
    /**
     * code:   * 1 - User.DoesNotExist   * 2 - Project.DoesNotExist 
     * @type {number}
     * @memberof SimulationGet200ResponseOneOfData
     */
    'code'?: SimulationGet200ResponseOneOfDataCodeEnum;
    /**
     * Error message
     * @type {string}
     * @memberof SimulationGet200ResponseOneOfData
     */
    'message'?: string;
}

export const SimulationGet200ResponseOneOfDataCodeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type SimulationGet200ResponseOneOfDataCodeEnum = typeof SimulationGet200ResponseOneOfDataCodeEnum[keyof typeof SimulationGet200ResponseOneOfDataCodeEnum];

/**
 * 
 * @export
 * @interface SimulationInfos
 */
export interface SimulationInfos {
    /**
     * 
     * @type {number}
     * @memberof SimulationInfos
     */
    'id'?: number;
    /**
     * ID of the user who launched the simulation.
     * @type {number}
     * @memberof SimulationInfos
     */
    'user'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimulationInfos
     */
    'simulation_type'?: SimulationInfosSimulationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SimulationInfos
     */
    'simulation_uuid'?: string;
    /**
     * The IP address of executing container.
     * @type {string}
     * @memberof SimulationInfos
     */
    'simulation_ip'?: string;
    /**
     * ArangoDB document ID for the study results, in the `Results` collection.
     * @type {string}
     * @memberof SimulationInfos
     */
    'result'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationInfos
     */
    'status'?: SimulationInfosStatusEnum;
    /**
     * The error level.
     * @type {string}
     * @memberof SimulationInfos
     */
    'error'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationInfos
     */
    'stage'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationInfos
     */
    'timings'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationInfos
     */
    'start_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulationInfos
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimulationInfos
     */
    'simulation_error_detail'?: string;
    /**
     * The emission_factors used in systems simulation.
     * @type {string}
     * @memberof SimulationInfos
     */
    'emission_factors'?: string;
}

export const SimulationInfosSimulationTypeEnum = {
    Demand: 'demand',
    Sizing: 'sizing',
    Systems: 'systems'
} as const;

export type SimulationInfosSimulationTypeEnum = typeof SimulationInfosSimulationTypeEnum[keyof typeof SimulationInfosSimulationTypeEnum];
export const SimulationInfosStatusEnum = {
    Checking: 'Checking',
    Pending: 'Pending',
    Launched: 'Launched',
    Running: 'Running',
    Done: 'Done',
    Failed: 'Failed',
    Aborted: 'Aborted'
} as const;

export type SimulationInfosStatusEnum = typeof SimulationInfosStatusEnum[keyof typeof SimulationInfosStatusEnum];

/**
 * 
 * @export
 * @interface SimulationStopDeleteRequest
 */
export interface SimulationStopDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SimulationStopDeleteRequest
     */
    'study_id': number;
    /**
     * 
     * @type {string}
     * @memberof SimulationStopDeleteRequest
     */
    'simulation_type': SimulationStopDeleteRequestSimulationTypeEnum;
}

export const SimulationStopDeleteRequestSimulationTypeEnum = {
    Demand: 'demand',
    Sizing: 'sizing',
    Systems: 'systems'
} as const;

export type SimulationStopDeleteRequestSimulationTypeEnum = typeof SimulationStopDeleteRequestSimulationTypeEnum[keyof typeof SimulationStopDeleteRequestSimulationTypeEnum];

/**
 * 
 * @export
 * @interface Study
 */
export interface Study {
    /**
     * 
     * @type {number}
     * @memberof Study
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Study
     */
    'project'?: number;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'description'?: string;
    /**
     * ArangoDB document ID for the study, in the `Study` collection
     * @type {string}
     * @memberof Study
     */
    'file'?: string;
    /**
     * The study imported layer image coordinates for buildings, as a JSON string.
     * @type {string}
     * @memberof Study
     */
    'building_image_coordinates'?: string | null;
    /**
     * The study imported layer image coordinates for networks, as a JSON string.
     * @type {string}
     * @memberof Study
     */
    'network_image_coordinates'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'creation_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'last_save_time'?: string;
    /**
     * ArangoDB document ID for the study\'s weather file, in the `WeatherFiles` collection
     * @type {string}
     * @memberof Study
     */
    'climate_file'?: string;
    /**
     * 
     * @type {string}
     * @memberof Study
     */
    'background_map'?: StudyBackgroundMapEnum;
}

export const StudyBackgroundMapEnum = {
    Ign: 'ign',
    Osm: 'osm'
} as const;

export type StudyBackgroundMapEnum = typeof StudyBackgroundMapEnum[keyof typeof StudyBackgroundMapEnum];

/**
 * @type StudyImportBuildingsPost200Response
 * @export
 */
export type StudyImportBuildingsPost200Response = ErrorResponse | StudyImportBuildingsPost200ResponseOneOf;

/**
 * 
 * @export
 * @interface StudyImportBuildingsPost200ResponseOneOf
 */
export interface StudyImportBuildingsPost200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof StudyImportBuildingsPost200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {StudyImportBuildingsPost200ResponseOneOfData}
     * @memberof StudyImportBuildingsPost200ResponseOneOf
     */
    'data'?: StudyImportBuildingsPost200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface StudyImportBuildingsPost200ResponseOneOfData
 */
export interface StudyImportBuildingsPost200ResponseOneOfData {
    /**
     * 
     * @type {string}
     * @memberof StudyImportBuildingsPost200ResponseOneOfData
     */
    'message'?: string;
    /**
     * 
     * @type {StudyImportBuildingsPost200ResponseOneOfDataZoneImport}
     * @memberof StudyImportBuildingsPost200ResponseOneOfData
     */
    'zone_import'?: StudyImportBuildingsPost200ResponseOneOfDataZoneImport;
}
/**
 * 
 * @export
 * @interface StudyImportBuildingsPost200ResponseOneOfDataZoneImport
 */
export interface StudyImportBuildingsPost200ResponseOneOfDataZoneImport {
    /**
     * 
     * @type {string}
     * @memberof StudyImportBuildingsPost200ResponseOneOfDataZoneImport
     */
    'type'?: StudyImportBuildingsPost200ResponseOneOfDataZoneImportTypeEnum;
    /**
     * 
     * @type {Array<FeaturesInner>}
     * @memberof StudyImportBuildingsPost200ResponseOneOfDataZoneImport
     */
    'features'?: Array<FeaturesInner>;
}

export const StudyImportBuildingsPost200ResponseOneOfDataZoneImportTypeEnum = {
    FeatureCollection: 'FeatureCollection'
} as const;

export type StudyImportBuildingsPost200ResponseOneOfDataZoneImportTypeEnum = typeof StudyImportBuildingsPost200ResponseOneOfDataZoneImportTypeEnum[keyof typeof StudyImportBuildingsPost200ResponseOneOfDataZoneImportTypeEnum];

/**
 * 
 * @export
 * @interface StudyImportBuildingsPostRequest
 */
export interface StudyImportBuildingsPostRequest {
    /**
     * 
     * @type {Polygon}
     * @memberof StudyImportBuildingsPostRequest
     */
    'geometry': Polygon;
}
/**
 * 
 * @export
 * @interface StudyPost200Response
 */
export interface StudyPost200Response {
    /**
     * 
     * @type {boolean}
     * @memberof StudyPost200Response
     */
    'status'?: boolean;
    /**
     * 
     * @type {StudyPost200ResponseData}
     * @memberof StudyPost200Response
     */
    'data'?: StudyPost200ResponseData;
}
/**
 * @type StudyPost200ResponseData
 * @export
 */
export type StudyPost200ResponseData = ErrorCodeResponse | Study;

/**
 * 
 * @export
 * @interface StudyPostRequest
 */
export interface StudyPostRequest {
    /**
     * 
     * @type {number}
     * @memberof StudyPostRequest
     */
    'project': number;
    /**
     * * A user must exist with this ID * It SHOULD have access to the project, but the API does not check it.  * Used for setting default weather_file according to project coordinates finding nearest in user\'s weather_file list. 
     * @type {number}
     * @memberof StudyPostRequest
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof StudyPostRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StudyPostRequest
     */
    'description': string;
}
/**
 * @type StudyStudyIdAddGeojsonPost200Response
 * @export
 */
export type StudyStudyIdAddGeojsonPost200Response = ErrorResponse | StudyStudyIdAddGeojsonPost200ResponseOneOf;

/**
 * 
 * @export
 * @interface StudyStudyIdAddGeojsonPost200ResponseOneOf
 */
export interface StudyStudyIdAddGeojsonPost200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof StudyStudyIdAddGeojsonPost200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StudyStudyIdAddGeojsonPost200ResponseOneOf
     */
    'data'?: boolean;
}
/**
 * 
 * @export
 * @interface StudyStudyIdAddGeojsonPostRequest
 */
export interface StudyStudyIdAddGeojsonPostRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyStudyIdAddGeojsonPostRequest
     */
    'collection_name': StudyStudyIdAddGeojsonPostRequestCollectionNameEnum;
    /**
     * 
     * @type {Geojson}
     * @memberof StudyStudyIdAddGeojsonPostRequest
     */
    'file': Geojson;
}

export const StudyStudyIdAddGeojsonPostRequestCollectionNameEnum = {
    ThermalZone: 'ThermalZone',
    DistrictHydroDistribution: 'DistrictHydroDistribution',
    DistrictGenerator: 'DistrictGenerator',
    DistrictPowerPlant: 'DistrictPowerPlant'
} as const;

export type StudyStudyIdAddGeojsonPostRequestCollectionNameEnum = typeof StudyStudyIdAddGeojsonPostRequestCollectionNameEnum[keyof typeof StudyStudyIdAddGeojsonPostRequestCollectionNameEnum];

/**
 * 
 * @export
 * @interface StudyStudyIdDuplicatePostRequest
 */
export interface StudyStudyIdDuplicatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyStudyIdDuplicatePostRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof StudyStudyIdDuplicatePostRequest
     */
    'description': string;
    /**
     * 
     * @type {ResultsToDuplicate}
     * @memberof StudyStudyIdDuplicatePostRequest
     */
    'results': ResultsToDuplicate;
}


/**
 * @type StudyStudyIdGeojsonGet200Response
 * @export
 */
export type StudyStudyIdGeojsonGet200Response = ErrorResponse | StudyStudyIdGeojsonGet200ResponseOneOf;

/**
 * 
 * @export
 * @interface StudyStudyIdGeojsonGet200ResponseOneOf
 */
export interface StudyStudyIdGeojsonGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof StudyStudyIdGeojsonGet200ResponseOneOf
     */
    'status': boolean;
    /**
     * 
     * @type {StudyStudyIdGeojsonGet200ResponseOneOfData}
     * @memberof StudyStudyIdGeojsonGet200ResponseOneOf
     */
    'data': StudyStudyIdGeojsonGet200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface StudyStudyIdGeojsonGet200ResponseOneOfData
 */
export interface StudyStudyIdGeojsonGet200ResponseOneOfData {
    /**
     * 
     * @type {Geojson}
     * @memberof StudyStudyIdGeojsonGet200ResponseOneOfData
     */
    'thermal_zone': Geojson;
    /**
     * 
     * @type {StudyStudyIdGeojsonGet200ResponseOneOfDataDistrictHydroDistribution}
     * @memberof StudyStudyIdGeojsonGet200ResponseOneOfData
     */
    'district_hydro_distribution': StudyStudyIdGeojsonGet200ResponseOneOfDataDistrictHydroDistribution;
    /**
     * 
     * @type {StudyStudyIdGeojsonGet200ResponseOneOfDataDistrictHydroDistribution}
     * @memberof StudyStudyIdGeojsonGet200ResponseOneOfData
     */
    'district_multi_generator': StudyStudyIdGeojsonGet200ResponseOneOfDataDistrictHydroDistribution;
    /**
     * 
     * @type {StudyStudyIdGeojsonGet200ResponseOneOfDataDistrictHydroDistribution}
     * @memberof StudyStudyIdGeojsonGet200ResponseOneOfData
     */
    'district_power_plant': StudyStudyIdGeojsonGet200ResponseOneOfDataDistrictHydroDistribution;
    /**
     * Deprecated.
     * @type {object}
     * @memberof StudyStudyIdGeojsonGet200ResponseOneOfData
     */
    'district_parameters': object;
}
/**
 * @type StudyStudyIdGeojsonGet200ResponseOneOfDataDistrictHydroDistribution
 * @export
 */
export type StudyStudyIdGeojsonGet200ResponseOneOfDataDistrictHydroDistribution = Geojson | object;

/**
 * @type StudyStudyIdGet200Response
 * @export
 */
export type StudyStudyIdGet200Response = ErrorResponse | StudyStudyIdGet200ResponseOneOf;

/**
 * 
 * @export
 * @interface StudyStudyIdGet200ResponseOneOf
 */
export interface StudyStudyIdGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof StudyStudyIdGet200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {Study}
     * @memberof StudyStudyIdGet200ResponseOneOf
     */
    'data'?: Study;
}
/**
 * 
 * @export
 * @interface StudyStudyIdUpdatePutRequest
 */
export interface StudyStudyIdUpdatePutRequest {
    /**
     * 
     * @type {string}
     * @memberof StudyStudyIdUpdatePutRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyStudyIdUpdatePutRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Geojson}
     * @memberof StudyStudyIdUpdatePutRequest
     */
    'geojson_zone'?: Geojson;
    /**
     * ArangoDB document ID for the study\'s weather file, in the `WeatherFiles` collection
     * @type {string}
     * @memberof StudyStudyIdUpdatePutRequest
     */
    'weather_file_key'?: string;
    /**
     * The study imported layer image coordinates for buildings, as a JSON string.
     * @type {string}
     * @memberof StudyStudyIdUpdatePutRequest
     */
    'building_image_coordinates'?: string;
    /**
     * The study imported layer image coordinates for networks, as a JSON string.
     * @type {string}
     * @memberof StudyStudyIdUpdatePutRequest
     */
    'network_image_coordinates'?: string;
    /**
     * ArangoDB document ID for the study, in the `Study` collection
     * @type {string}
     * @memberof StudyStudyIdUpdatePutRequest
     */
    'file'?: string;
}
/**
 * @type StudySystemsGet200Response
 * @export
 */
export type StudySystemsGet200Response = ErrorResponse | StudySystemsGet200ResponseOneOf;

/**
 * 
 * @export
 * @interface StudySystemsGet200ResponseOneOf
 */
export interface StudySystemsGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof StudySystemsGet200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {StudySystemsGet200ResponseOneOfData}
     * @memberof StudySystemsGet200ResponseOneOf
     */
    'data'?: StudySystemsGet200ResponseOneOfData;
}
/**
 * - Each property has every system with for each the type for the property - Ex : {   ...   level : {   ...   \"ZoneElectricHeaterElectricityHeating\" : \"ThermalZone\",   \"BuildingBoilerGasDHWStandard\": \"Building\",   ...   }     fuel : {     ...   \"ZoneElectricHeaterElectricityHeating\" : \"Electricity\",   \"BuildingBoilerGasDHWStandard\": \"Gas\",   ...   }   ...   } 
 * @export
 * @interface StudySystemsGet200ResponseOneOfData
 */
export interface StudySystemsGet200ResponseOneOfData {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'building_substation'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'district_substation'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'emitter_temperature'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'fuel'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'generator'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'hydrodistribution_auxiliary'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'level'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'network'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'service'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'subnetwork'?: { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StudySystemsGet200ResponseOneOfData
     */
    'technology'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'login'?: string;
    /**
     * Unused
     * @type {string}
     * @memberof User
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'uuid'?: string;
    /**
     * Deprecated. Use Keycloak roles instead.
     * @type {string}
     * @memberof User
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name'?: string;
}
/**
 * 
 * @export
 * @interface UserInProject
 */
export interface UserInProject {
    /**
     * 
     * @type {number}
     * @memberof UserInProject
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserInProject
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInProject
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInProject
     */
    'last_name'?: string;
    /**
     * ID of the user\'s profile in the project (corresponds to Owner, Member, ...)
     * @type {number}
     * @memberof UserInProject
     */
    'profile'?: number;
}
/**
 * @type UsersLoginSsoPost200Response
 * @export
 */
export type UsersLoginSsoPost200Response = UsersLoginSsoPost200ResponseOneOf | object;

/**
 * 
 * @export
 * @interface UsersLoginSsoPost200ResponseOneOf
 */
export interface UsersLoginSsoPost200ResponseOneOf {
    /**
     * 
     * @type {string}
     * @memberof UsersLoginSsoPost200ResponseOneOf
     */
    'Status'?: string;
    /**
     * 
     * @type {User}
     * @memberof UsersLoginSsoPost200ResponseOneOf
     */
    'User'?: User;
}
/**
 * 
 * @export
 * @interface UsersLoginSsoPostRequest
 */
export interface UsersLoginSsoPostRequest {
    /**
     * (uppercase L, not a documentation mistake ;-) )
     * @type {string}
     * @memberof UsersLoginSsoPostRequest
     */
    'Login': string;
    /**
     * 
     * @type {string}
     * @memberof UsersLoginSsoPostRequest
     */
    'uuid': string;
    /**
     * Deprecated. Free text, persisted as is. (Use Keycloak roles instead.)
     * @type {string}
     * @memberof UsersLoginSsoPostRequest
     */
    'role': string;
    /**
     * 
     * @type {string}
     * @memberof UsersLoginSsoPostRequest
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UsersLoginSsoPostRequest
     */
    'last_name': string;
}
/**
 * @type UsersProjectsUserIdGet200Response
 * @export
 */
export type UsersProjectsUserIdGet200Response = ErrorResponse | UsersProjectsUserIdGet200ResponseOneOf;

/**
 * 
 * @export
 * @interface UsersProjectsUserIdGet200ResponseOneOf
 */
export interface UsersProjectsUserIdGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof UsersProjectsUserIdGet200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * List of the projects to which the user has access
     * @type {Array<ProjectWithUsers>}
     * @memberof UsersProjectsUserIdGet200ResponseOneOf
     */
    'data'?: Array<ProjectWithUsers>;
}
/**
 * 
 * @export
 * @interface WeatherFile
 */
export interface WeatherFile {
    /**
     * 
     * @type {string}
     * @memberof WeatherFile
     */
    '_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherFile
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WeatherFile
     */
    'DJU'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WeatherFile
     */
    'default'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WeatherFile
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherFile
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherFile
     */
    'creation_time'?: string;
}
/**
 * @type WeatherFileGetInfoGet200Response
 * @export
 */
export type WeatherFileGetInfoGet200Response = WeatherFileGetInfoGet200ResponseOneOf | WeatherFileGetInfoGet200ResponseOneOf1;

/**
 * 
 * @export
 * @interface WeatherFileGetInfoGet200ResponseOneOf
 */
export interface WeatherFileGetInfoGet200ResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof WeatherFileGetInfoGet200ResponseOneOf
     */
    'status'?: boolean;
    /**
     * 
     * @type {WeatherFileGetInfoGet200ResponseOneOfData}
     * @memberof WeatherFileGetInfoGet200ResponseOneOf
     */
    'data'?: WeatherFileGetInfoGet200ResponseOneOfData;
}
/**
 * 
 * @export
 * @interface WeatherFileGetInfoGet200ResponseOneOf1
 */
export interface WeatherFileGetInfoGet200ResponseOneOf1 {
    /**
     * 
     * @type {ErrorCodeResponse}
     * @memberof WeatherFileGetInfoGet200ResponseOneOf1
     */
    'data'?: ErrorCodeResponse;
}
/**
 * 
 * @export
 * @interface WeatherFileGetInfoGet200ResponseOneOfData
 */
export interface WeatherFileGetInfoGet200ResponseOneOfData {
    /**
     * 
     * @type {Array<WeatherFile>}
     * @memberof WeatherFileGetInfoGet200ResponseOneOfData
     */
    'user': Array<WeatherFile>;
    /**
     * 
     * @type {Array<WeatherFile>}
     * @memberof WeatherFileGetInfoGet200ResponseOneOfData
     */
    'default': Array<WeatherFile>;
    /**
     * 
     * @type {Array<WeatherFile>}
     * @memberof WeatherFileGetInfoGet200ResponseOneOfData
     */
    'project'?: Array<WeatherFile>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if a different user than the one passed is in use of the project. 
         * @param {BusySharedProjectPostRequest} [busySharedProjectPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        busySharedProjectPost: async (busySharedProjectPostRequest?: BusySharedProjectPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/busy-shared-project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(busySharedProjectPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if a different user than the one passed is in use of the study. 
         * @param {BusySharedStudyPostRequest} [busySharedStudyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        busySharedStudyPost: async (busySharedStudyPostRequest?: BusySharedStudyPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/busy-shared-study/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(busySharedStudyPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [withDefault] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCurvesGet: async (userId: number, withDefault?: boolean, projectId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('loadCurvesGet', 'userId', userId)
            const localVarPath = `/load-curves/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (withDefault !== undefined) {
                localVarQueryParameter['with_default'] = withDefault;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a project
         * @param {ProjectPostRequest} [projectPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectPost: async (projectPostRequest?: ProjectPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdDeleteDelete: async (projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectProjectIdDeleteDelete', 'projectId', projectId)
            const localVarPath = `/project/{project_id}/delete/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ProjectProjectIdDuplicatePostRequest} [projectProjectIdDuplicatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdDuplicatePost: async (projectId: number, projectProjectIdDuplicatePostRequest?: ProjectProjectIdDuplicatePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectProjectIdDuplicatePost', 'projectId', projectId)
            const localVarPath = `/project/{project_id}/duplicate/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectProjectIdDuplicatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a project infos
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdGet: async (projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectProjectIdGet', 'projectId', projectId)
            const localVarPath = `/project/{project_id}/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a project\'s studies with their simulations
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdStudiesGet: async (projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectProjectIdStudiesGet', 'projectId', projectId)
            const localVarPath = `/project/{project_id}/studies/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ProjectProjectIdUpdatePutRequest} [projectProjectIdUpdatePutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdUpdatePut: async (projectId: number, projectProjectIdUpdatePutRequest?: ProjectProjectIdUpdatePutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectProjectIdUpdatePut', 'projectId', projectId)
            const localVarPath = `/project/{project_id}/update/`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectProjectIdUpdatePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get scenario list
         * @param {number} userId 
         * @param {boolean} withDefault If true the default scenarios are returned.
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenarioGetAllGet: async (userId: number, withDefault: boolean, projectId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('scenarioGetAllGet', 'userId', userId)
            // verify required parameter 'withDefault' is not null or undefined
            assertParamExists('scenarioGetAllGet', 'withDefault', withDefault)
            const localVarPath = `/scenario/get-all/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (withDefault !== undefined) {
                localVarQueryParameter['with_default'] = withDefault;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a simulation
         * @param {SimulationCreatePostRequest} [simulationCreatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulationCreatePost: async (simulationCreatePostRequest?: SimulationCreatePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/simulation/create/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simulationCreatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SimulationDeleteDeleteRequest} [simulationDeleteDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulationDeleteDelete: async (simulationDeleteDeleteRequest?: SimulationDeleteDeleteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/simulation/delete/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simulationDeleteDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get study simulation state
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulationGet: async (studyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('simulationGet', 'studyId', studyId)
            const localVarPath = `/simulation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyId !== undefined) {
                localVarQueryParameter['study_id'] = studyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SimulationStopDeleteRequest} [simulationStopDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulationStopDelete: async (simulationStopDeleteRequest?: SimulationStopDeleteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/simulation/stop/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simulationStopDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import buildings for drawed perimeter. 
         * @param {StudyImportBuildingsPostRequest} [studyImportBuildingsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyImportBuildingsPost: async (studyImportBuildingsPostRequest?: StudyImportBuildingsPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/study/import-buildings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyImportBuildingsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a study
         * @param {StudyPostRequest} [studyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyPost: async (studyPostRequest?: StudyPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/study/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add objects by passing collection_name and a GeoJSON. 
         * @param {number} studyId 
         * @param {StudyStudyIdAddGeojsonPostRequest} [studyStudyIdAddGeojsonPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdAddGeojsonPost: async (studyId: number, studyStudyIdAddGeojsonPostRequest?: StudyStudyIdAddGeojsonPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('studyStudyIdAddGeojsonPost', 'studyId', studyId)
            const localVarPath = `/study/{study_id}/add-geojson/`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(studyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyStudyIdAddGeojsonPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdDeleteDelete: async (studyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('studyStudyIdDeleteDelete', 'studyId', studyId)
            const localVarPath = `/study/{study_id}/delete/`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(studyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} studyId 
         * @param {StudyStudyIdDuplicatePostRequest} [studyStudyIdDuplicatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdDuplicatePost: async (studyId: number, studyStudyIdDuplicatePostRequest?: StudyStudyIdDuplicatePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('studyStudyIdDuplicatePost', 'studyId', studyId)
            const localVarPath = `/study/{study_id}/duplicate/`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(studyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyStudyIdDuplicatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the requested geometricals objects of a study in GeoJSON format
         * @param {number} studyId 
         * @param {boolean} [zone] 
         * @param {string} [districtHydroDistribution] 
         * @param {boolean} [districtMultiGenerator] 
         * @param {boolean} [districtPowerPlant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdGeojsonGet: async (studyId: number, zone?: boolean, districtHydroDistribution?: string, districtMultiGenerator?: boolean, districtPowerPlant?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('studyStudyIdGeojsonGet', 'studyId', studyId)
            const localVarPath = `/study/{study_id}/geojson/`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(studyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (zone !== undefined) {
                localVarQueryParameter['zone'] = zone;
            }

            if (districtHydroDistribution !== undefined) {
                localVarQueryParameter['district_hydro_distribution'] = districtHydroDistribution;
            }

            if (districtMultiGenerator !== undefined) {
                localVarQueryParameter['district_multi_generator'] = districtMultiGenerator;
            }

            if (districtPowerPlant !== undefined) {
                localVarQueryParameter['district_power_plant'] = districtPowerPlant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a study infos
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdGet: async (studyId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('studyStudyIdGet', 'studyId', studyId)
            const localVarPath = `/study/{study_id}/`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(studyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} studyId 
         * @param {StudyStudyIdUpdatePutRequest} [studyStudyIdUpdatePutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdUpdatePut: async (studyId: number, studyStudyIdUpdatePutRequest?: StudyStudyIdUpdatePutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyId' is not null or undefined
            assertParamExists('studyStudyIdUpdatePut', 'studyId', studyId)
            const localVarPath = `/study/{study_id}/update/`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(studyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyStudyIdUpdatePutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Useful for matching demand/scenarios/systems.
         * @summary Get a file describing systems used in Dimosim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studySystemsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/study/systems/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Look for an existing user with the given `login`. If not found, create it and return the newly created user. Otherwise, update and return the _OLD user state, before the update_. 
         * @summary Upsert a user. Should be called after a Keycloak login.
         * @param {UsersLoginSsoPostRequest} [usersLoginSsoPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginSsoPost: async (usersLoginSsoPostRequest?: UsersLoginSsoPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/login-sso/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersLoginSsoPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s projects with all their users
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersProjectsUserIdGet: async (userId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('usersProjectsUserIdGet', 'userId', userId)
            const localVarPath = `/users/projects/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} _default If true the default weather files are returned.
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherFileGetInfoGet: async (userId: number, _default: boolean, projectId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('weatherFileGetInfoGet', 'userId', userId)
            // verify required parameter '_default' is not null or undefined
            assertParamExists('weatherFileGetInfoGet', '_default', _default)
            const localVarPath = `/weather-file/get-info/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (_default !== undefined) {
                localVarQueryParameter['default'] = _default;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if a different user than the one passed is in use of the project. 
         * @param {BusySharedProjectPostRequest} [busySharedProjectPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async busySharedProjectPost(busySharedProjectPostRequest?: BusySharedProjectPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusySharedProjectPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.busySharedProjectPost(busySharedProjectPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.busySharedProjectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Check if a different user than the one passed is in use of the study. 
         * @param {BusySharedStudyPostRequest} [busySharedStudyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async busySharedStudyPost(busySharedStudyPostRequest?: BusySharedStudyPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusySharedStudyPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.busySharedStudyPost(busySharedStudyPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.busySharedStudyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [withDefault] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCurvesGet(userId: number, withDefault?: boolean, projectId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoadCurvesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCurvesGet(userId, withDefault, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.loadCurvesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a project
         * @param {ProjectPostRequest} [projectPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectPost(projectPostRequest?: ProjectPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectPost(projectPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectIdDeleteDelete(projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectProjectIdDeleteDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectIdDeleteDelete(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectProjectIdDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ProjectProjectIdDuplicatePostRequest} [projectProjectIdDuplicatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectIdDuplicatePost(projectId: number, projectProjectIdDuplicatePostRequest?: ProjectProjectIdDuplicatePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectIdDuplicatePost(projectId, projectProjectIdDuplicatePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectProjectIdDuplicatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a project infos
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectIdGet(projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectProjectIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectIdGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a project\'s studies with their simulations
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectIdStudiesGet(projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectProjectIdStudiesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectIdStudiesGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectProjectIdStudiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ProjectProjectIdUpdatePutRequest} [projectProjectIdUpdatePutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectIdUpdatePut(projectId: number, projectProjectIdUpdatePutRequest?: ProjectProjectIdUpdatePutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectIdUpdatePut(projectId, projectProjectIdUpdatePutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.projectProjectIdUpdatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get scenario list
         * @param {number} userId 
         * @param {boolean} withDefault If true the default scenarios are returned.
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scenarioGetAllGet(userId: number, withDefault: boolean, projectId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioGetAllGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scenarioGetAllGet(userId, withDefault, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.scenarioGetAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a simulation
         * @param {SimulationCreatePostRequest} [simulationCreatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulationCreatePost(simulationCreatePostRequest?: SimulationCreatePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationCreatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulationCreatePost(simulationCreatePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.simulationCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SimulationDeleteDeleteRequest} [simulationDeleteDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulationDeleteDelete(simulationDeleteDeleteRequest?: SimulationDeleteDeleteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationDeleteDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulationDeleteDelete(simulationDeleteDeleteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.simulationDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get study simulation state
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulationGet(studyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulationGet(studyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.simulationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SimulationStopDeleteRequest} [simulationStopDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulationStopDelete(simulationStopDeleteRequest?: SimulationStopDeleteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationDeleteDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulationStopDelete(simulationStopDeleteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.simulationStopDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Import buildings for drawed perimeter. 
         * @param {StudyImportBuildingsPostRequest} [studyImportBuildingsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studyImportBuildingsPost(studyImportBuildingsPostRequest?: StudyImportBuildingsPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyImportBuildingsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studyImportBuildingsPost(studyImportBuildingsPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studyImportBuildingsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a study
         * @param {StudyPostRequest} [studyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studyPost(studyPostRequest?: StudyPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studyPost(studyPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add objects by passing collection_name and a GeoJSON. 
         * @param {number} studyId 
         * @param {StudyStudyIdAddGeojsonPostRequest} [studyStudyIdAddGeojsonPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studyStudyIdAddGeojsonPost(studyId: number, studyStudyIdAddGeojsonPostRequest?: StudyStudyIdAddGeojsonPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyStudyIdAddGeojsonPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studyStudyIdAddGeojsonPost(studyId, studyStudyIdAddGeojsonPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studyStudyIdAddGeojsonPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studyStudyIdDeleteDelete(studyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectProjectIdDeleteDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studyStudyIdDeleteDelete(studyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studyStudyIdDeleteDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} studyId 
         * @param {StudyStudyIdDuplicatePostRequest} [studyStudyIdDuplicatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studyStudyIdDuplicatePost(studyId: number, studyStudyIdDuplicatePostRequest?: StudyStudyIdDuplicatePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studyStudyIdDuplicatePost(studyId, studyStudyIdDuplicatePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studyStudyIdDuplicatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the requested geometricals objects of a study in GeoJSON format
         * @param {number} studyId 
         * @param {boolean} [zone] 
         * @param {string} [districtHydroDistribution] 
         * @param {boolean} [districtMultiGenerator] 
         * @param {boolean} [districtPowerPlant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studyStudyIdGeojsonGet(studyId: number, zone?: boolean, districtHydroDistribution?: string, districtMultiGenerator?: boolean, districtPowerPlant?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyStudyIdGeojsonGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studyStudyIdGeojsonGet(studyId, zone, districtHydroDistribution, districtMultiGenerator, districtPowerPlant, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studyStudyIdGeojsonGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a study infos
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studyStudyIdGet(studyId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyStudyIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studyStudyIdGet(studyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studyStudyIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} studyId 
         * @param {StudyStudyIdUpdatePutRequest} [studyStudyIdUpdatePutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studyStudyIdUpdatePut(studyId: number, studyStudyIdUpdatePutRequest?: StudyStudyIdUpdatePutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyStudyIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studyStudyIdUpdatePut(studyId, studyStudyIdUpdatePutRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studyStudyIdUpdatePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Useful for matching demand/scenarios/systems.
         * @summary Get a file describing systems used in Dimosim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studySystemsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudySystemsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studySystemsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.studySystemsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Look for an existing user with the given `login`. If not found, create it and return the newly created user. Otherwise, update and return the _OLD user state, before the update_. 
         * @summary Upsert a user. Should be called after a Keycloak login.
         * @param {UsersLoginSsoPostRequest} [usersLoginSsoPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersLoginSsoPost(usersLoginSsoPostRequest?: UsersLoginSsoPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersLoginSsoPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersLoginSsoPost(usersLoginSsoPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersLoginSsoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get user\'s projects with all their users
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersProjectsUserIdGet(userId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersProjectsUserIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersProjectsUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.usersProjectsUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} _default If true the default weather files are returned.
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async weatherFileGetInfoGet(userId: number, _default: boolean, projectId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WeatherFileGetInfoGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.weatherFileGetInfoGet(userId, _default, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.weatherFileGetInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if a different user than the one passed is in use of the project. 
         * @param {BusySharedProjectPostRequest} [busySharedProjectPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        busySharedProjectPost(busySharedProjectPostRequest?: BusySharedProjectPostRequest, options?: any): AxiosPromise<BusySharedProjectPost200Response> {
            return localVarFp.busySharedProjectPost(busySharedProjectPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if a different user than the one passed is in use of the study. 
         * @param {BusySharedStudyPostRequest} [busySharedStudyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        busySharedStudyPost(busySharedStudyPostRequest?: BusySharedStudyPostRequest, options?: any): AxiosPromise<BusySharedStudyPost200Response> {
            return localVarFp.busySharedStudyPost(busySharedStudyPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [withDefault] 
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCurvesGet(userId: number, withDefault?: boolean, projectId?: number, options?: any): AxiosPromise<LoadCurvesGet200Response> {
            return localVarFp.loadCurvesGet(userId, withDefault, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a project
         * @param {ProjectPostRequest} [projectPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectPost(projectPostRequest?: ProjectPostRequest, options?: any): AxiosPromise<ProjectPost200Response> {
            return localVarFp.projectPost(projectPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdDeleteDelete(projectId: number, options?: any): AxiosPromise<ProjectProjectIdDeleteDelete200Response> {
            return localVarFp.projectProjectIdDeleteDelete(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ProjectProjectIdDuplicatePostRequest} [projectProjectIdDuplicatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdDuplicatePost(projectId: number, projectProjectIdDuplicatePostRequest?: ProjectProjectIdDuplicatePostRequest, options?: any): AxiosPromise<ProjectPost200Response> {
            return localVarFp.projectProjectIdDuplicatePost(projectId, projectProjectIdDuplicatePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a project infos
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdGet(projectId: number, options?: any): AxiosPromise<ProjectProjectIdGet200Response> {
            return localVarFp.projectProjectIdGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a project\'s studies with their simulations
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdStudiesGet(projectId: number, options?: any): AxiosPromise<ProjectProjectIdStudiesGet200Response> {
            return localVarFp.projectProjectIdStudiesGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {ProjectProjectIdUpdatePutRequest} [projectProjectIdUpdatePutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectIdUpdatePut(projectId: number, projectProjectIdUpdatePutRequest?: ProjectProjectIdUpdatePutRequest, options?: any): AxiosPromise<ProjectPost200Response> {
            return localVarFp.projectProjectIdUpdatePut(projectId, projectProjectIdUpdatePutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get scenario list
         * @param {number} userId 
         * @param {boolean} withDefault If true the default scenarios are returned.
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scenarioGetAllGet(userId: number, withDefault: boolean, projectId?: number, options?: any): AxiosPromise<ScenarioGetAllGet200Response> {
            return localVarFp.scenarioGetAllGet(userId, withDefault, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a simulation
         * @param {SimulationCreatePostRequest} [simulationCreatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulationCreatePost(simulationCreatePostRequest?: SimulationCreatePostRequest, options?: any): AxiosPromise<SimulationCreatePost200Response> {
            return localVarFp.simulationCreatePost(simulationCreatePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SimulationDeleteDeleteRequest} [simulationDeleteDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulationDeleteDelete(simulationDeleteDeleteRequest?: SimulationDeleteDeleteRequest, options?: any): AxiosPromise<SimulationDeleteDelete200Response> {
            return localVarFp.simulationDeleteDelete(simulationDeleteDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get study simulation state
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulationGet(studyId: number, options?: any): AxiosPromise<SimulationGet200Response> {
            return localVarFp.simulationGet(studyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SimulationStopDeleteRequest} [simulationStopDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulationStopDelete(simulationStopDeleteRequest?: SimulationStopDeleteRequest, options?: any): AxiosPromise<SimulationDeleteDelete200Response> {
            return localVarFp.simulationStopDelete(simulationStopDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import buildings for drawed perimeter. 
         * @param {StudyImportBuildingsPostRequest} [studyImportBuildingsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyImportBuildingsPost(studyImportBuildingsPostRequest?: StudyImportBuildingsPostRequest, options?: any): AxiosPromise<StudyImportBuildingsPost200Response> {
            return localVarFp.studyImportBuildingsPost(studyImportBuildingsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a study
         * @param {StudyPostRequest} [studyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyPost(studyPostRequest?: StudyPostRequest, options?: any): AxiosPromise<StudyPost200Response> {
            return localVarFp.studyPost(studyPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add objects by passing collection_name and a GeoJSON. 
         * @param {number} studyId 
         * @param {StudyStudyIdAddGeojsonPostRequest} [studyStudyIdAddGeojsonPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdAddGeojsonPost(studyId: number, studyStudyIdAddGeojsonPostRequest?: StudyStudyIdAddGeojsonPostRequest, options?: any): AxiosPromise<StudyStudyIdAddGeojsonPost200Response> {
            return localVarFp.studyStudyIdAddGeojsonPost(studyId, studyStudyIdAddGeojsonPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdDeleteDelete(studyId: number, options?: any): AxiosPromise<ProjectProjectIdDeleteDelete200Response> {
            return localVarFp.studyStudyIdDeleteDelete(studyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} studyId 
         * @param {StudyStudyIdDuplicatePostRequest} [studyStudyIdDuplicatePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdDuplicatePost(studyId: number, studyStudyIdDuplicatePostRequest?: StudyStudyIdDuplicatePostRequest, options?: any): AxiosPromise<StudyPost200Response> {
            return localVarFp.studyStudyIdDuplicatePost(studyId, studyStudyIdDuplicatePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the requested geometricals objects of a study in GeoJSON format
         * @param {number} studyId 
         * @param {boolean} [zone] 
         * @param {string} [districtHydroDistribution] 
         * @param {boolean} [districtMultiGenerator] 
         * @param {boolean} [districtPowerPlant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdGeojsonGet(studyId: number, zone?: boolean, districtHydroDistribution?: string, districtMultiGenerator?: boolean, districtPowerPlant?: boolean, options?: any): AxiosPromise<StudyStudyIdGeojsonGet200Response> {
            return localVarFp.studyStudyIdGeojsonGet(studyId, zone, districtHydroDistribution, districtMultiGenerator, districtPowerPlant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a study infos
         * @param {number} studyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdGet(studyId: number, options?: any): AxiosPromise<StudyStudyIdGet200Response> {
            return localVarFp.studyStudyIdGet(studyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} studyId 
         * @param {StudyStudyIdUpdatePutRequest} [studyStudyIdUpdatePutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studyStudyIdUpdatePut(studyId: number, studyStudyIdUpdatePutRequest?: StudyStudyIdUpdatePutRequest, options?: any): AxiosPromise<StudyStudyIdGet200Response> {
            return localVarFp.studyStudyIdUpdatePut(studyId, studyStudyIdUpdatePutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Useful for matching demand/scenarios/systems.
         * @summary Get a file describing systems used in Dimosim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studySystemsGet(options?: any): AxiosPromise<StudySystemsGet200Response> {
            return localVarFp.studySystemsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Look for an existing user with the given `login`. If not found, create it and return the newly created user. Otherwise, update and return the _OLD user state, before the update_. 
         * @summary Upsert a user. Should be called after a Keycloak login.
         * @param {UsersLoginSsoPostRequest} [usersLoginSsoPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLoginSsoPost(usersLoginSsoPostRequest?: UsersLoginSsoPostRequest, options?: any): AxiosPromise<UsersLoginSsoPost200Response> {
            return localVarFp.usersLoginSsoPost(usersLoginSsoPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s projects with all their users
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersProjectsUserIdGet(userId: number, options?: any): AxiosPromise<UsersProjectsUserIdGet200Response> {
            return localVarFp.usersProjectsUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} _default If true the default weather files are returned.
         * @param {number} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        weatherFileGetInfoGet(userId: number, _default: boolean, projectId?: number, options?: any): AxiosPromise<WeatherFileGetInfoGet200Response> {
            return localVarFp.weatherFileGetInfoGet(userId, _default, projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Check if a different user than the one passed is in use of the project. 
     * @param {BusySharedProjectPostRequest} [busySharedProjectPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public busySharedProjectPost(busySharedProjectPostRequest?: BusySharedProjectPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).busySharedProjectPost(busySharedProjectPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if a different user than the one passed is in use of the study. 
     * @param {BusySharedStudyPostRequest} [busySharedStudyPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public busySharedStudyPost(busySharedStudyPostRequest?: BusySharedStudyPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).busySharedStudyPost(busySharedStudyPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {boolean} [withDefault] 
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loadCurvesGet(userId: number, withDefault?: boolean, projectId?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loadCurvesGet(userId, withDefault, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a project
     * @param {ProjectPostRequest} [projectPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectPost(projectPostRequest?: ProjectPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectPost(projectPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectProjectIdDeleteDelete(projectId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectProjectIdDeleteDelete(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {ProjectProjectIdDuplicatePostRequest} [projectProjectIdDuplicatePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectProjectIdDuplicatePost(projectId: number, projectProjectIdDuplicatePostRequest?: ProjectProjectIdDuplicatePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectProjectIdDuplicatePost(projectId, projectProjectIdDuplicatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a project infos
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectProjectIdGet(projectId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectProjectIdGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a project\'s studies with their simulations
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectProjectIdStudiesGet(projectId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectProjectIdStudiesGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {ProjectProjectIdUpdatePutRequest} [projectProjectIdUpdatePutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public projectProjectIdUpdatePut(projectId: number, projectProjectIdUpdatePutRequest?: ProjectProjectIdUpdatePutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).projectProjectIdUpdatePut(projectId, projectProjectIdUpdatePutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get scenario list
     * @param {number} userId 
     * @param {boolean} withDefault If true the default scenarios are returned.
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public scenarioGetAllGet(userId: number, withDefault: boolean, projectId?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).scenarioGetAllGet(userId, withDefault, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a simulation
     * @param {SimulationCreatePostRequest} [simulationCreatePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public simulationCreatePost(simulationCreatePostRequest?: SimulationCreatePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).simulationCreatePost(simulationCreatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SimulationDeleteDeleteRequest} [simulationDeleteDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public simulationDeleteDelete(simulationDeleteDeleteRequest?: SimulationDeleteDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).simulationDeleteDelete(simulationDeleteDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get study simulation state
     * @param {number} studyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public simulationGet(studyId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).simulationGet(studyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SimulationStopDeleteRequest} [simulationStopDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public simulationStopDelete(simulationStopDeleteRequest?: SimulationStopDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).simulationStopDelete(simulationStopDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import buildings for drawed perimeter. 
     * @param {StudyImportBuildingsPostRequest} [studyImportBuildingsPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studyImportBuildingsPost(studyImportBuildingsPostRequest?: StudyImportBuildingsPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studyImportBuildingsPost(studyImportBuildingsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a study
     * @param {StudyPostRequest} [studyPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studyPost(studyPostRequest?: StudyPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studyPost(studyPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add objects by passing collection_name and a GeoJSON. 
     * @param {number} studyId 
     * @param {StudyStudyIdAddGeojsonPostRequest} [studyStudyIdAddGeojsonPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studyStudyIdAddGeojsonPost(studyId: number, studyStudyIdAddGeojsonPostRequest?: StudyStudyIdAddGeojsonPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studyStudyIdAddGeojsonPost(studyId, studyStudyIdAddGeojsonPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} studyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studyStudyIdDeleteDelete(studyId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studyStudyIdDeleteDelete(studyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} studyId 
     * @param {StudyStudyIdDuplicatePostRequest} [studyStudyIdDuplicatePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studyStudyIdDuplicatePost(studyId: number, studyStudyIdDuplicatePostRequest?: StudyStudyIdDuplicatePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studyStudyIdDuplicatePost(studyId, studyStudyIdDuplicatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the requested geometricals objects of a study in GeoJSON format
     * @param {number} studyId 
     * @param {boolean} [zone] 
     * @param {string} [districtHydroDistribution] 
     * @param {boolean} [districtMultiGenerator] 
     * @param {boolean} [districtPowerPlant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studyStudyIdGeojsonGet(studyId: number, zone?: boolean, districtHydroDistribution?: string, districtMultiGenerator?: boolean, districtPowerPlant?: boolean, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studyStudyIdGeojsonGet(studyId, zone, districtHydroDistribution, districtMultiGenerator, districtPowerPlant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a study infos
     * @param {number} studyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studyStudyIdGet(studyId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studyStudyIdGet(studyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} studyId 
     * @param {StudyStudyIdUpdatePutRequest} [studyStudyIdUpdatePutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studyStudyIdUpdatePut(studyId: number, studyStudyIdUpdatePutRequest?: StudyStudyIdUpdatePutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studyStudyIdUpdatePut(studyId, studyStudyIdUpdatePutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Useful for matching demand/scenarios/systems.
     * @summary Get a file describing systems used in Dimosim.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public studySystemsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).studySystemsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Look for an existing user with the given `login`. If not found, create it and return the newly created user. Otherwise, update and return the _OLD user state, before the update_. 
     * @summary Upsert a user. Should be called after a Keycloak login.
     * @param {UsersLoginSsoPostRequest} [usersLoginSsoPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersLoginSsoPost(usersLoginSsoPostRequest?: UsersLoginSsoPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersLoginSsoPost(usersLoginSsoPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s projects with all their users
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public usersProjectsUserIdGet(userId: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).usersProjectsUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {boolean} _default If true the default weather files are returned.
     * @param {number} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public weatherFileGetInfoGet(userId: number, _default: boolean, projectId?: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).weatherFileGetInfoGet(userId, _default, projectId, options).then((request) => request(this.axios, this.basePath));
    }
}



