import { createApp } from 'vue';
import 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { isUsingMocks, useApi } from '@/api';
// eslint-disable-next-line no-restricted-imports
import * as i18n from '@/plugins/i18n';
import keycloak from '@/plugins/keycloak';
import { initSentry } from '@/plugins/sentry';
import { Toast, toastOptions } from '@/plugins/vue-toastification';
import { createVuetify } from '@/plugins/vuetify';
import { createRouter } from '@/router';
import App from '@/views/app/App.vue';

const app = createApp(App);

if (!isUsingMocks()) {
  await keycloak.initialize();
}

app.use(i18n.initialize());
app.use(createVuetify(i18n.getLocale()));
app.use(Toast, toastOptions);

const router = createRouter();

if (import.meta.env.VITE_SENTRY_ENABLE === 'true') {
  initSentry(app, router, await useApi().currentUser);
}

app.use(router);

app.mount('#app');
