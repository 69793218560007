/* eslint-disable class-methods-use-this */

import type { ClosedRing } from '@/components/soon-shared/util';
import { geoJSONCoordinatesToPolygonGeometry } from '@/util/geography';
import { polygon, tesselate, transformScale } from '@turf/turf';
import type { LatLngLiteral } from 'leaflet';
import { ForbiddenAccessError, InvalidInputError, NotFoundError } from '../errors';
import { checkProjectsAccess } from '../project/project.mock';
import { performanceOptions, usageCodeOptions } from '../thermal-zone/thermal-zone.interface';
import { type CurrentUseState, type ResultType } from '../types';
import { fakeApiCallDelay } from '../util';
import type {
  ImportedBuilding,
  Study,
  StudyApi,
  StudyForm,
  StudyInfo,
  StudySimulationsType,
  StudyStepState,
} from './study.interface';

const generateStudy = ({
  id,
  projectId,
  name,
  description = '',
  buildingImageCoordinates = [],
  networkImageCoordinates = [],
  buildingModellingState,
  resultsBuildingModellingState,
  systemsModellingState,
  resultsConsumptionsState,
}: {
  id: number;
  projectId: number;
  name: string;
  description?: string;
  buildingImageCoordinates?: LatLngLiteral[];
  networkImageCoordinates?: LatLngLiteral[];
  buildingModellingState: StudyStepState;
  resultsBuildingModellingState: StudyStepState;
  systemsModellingState: StudyStepState;
  resultsConsumptionsState: StudyStepState;
}) => ({
  id,
  projectId,
  name,
  description,
  building_image_coordinates: buildingImageCoordinates,
  network_image_coordinates: networkImageCoordinates,
  savedAt: new Date(),
  state: {
    statesByStep: {
      buildingModelling: {
        state: buildingModellingState,
        url: `/study/${id}/buildings-modelling`,
      },
      resultsBuildingModelling: {
        state: resultsBuildingModellingState,
        url: `/study/${id}/results-demands`,
      },
      systemsModelling: {
        state: systemsModellingState,
        url: `/study/${id}/study-systems`,
      },
      resultsConsumptions: {
        state: resultsConsumptionsState,
        url: `/study/${id}/results-consumptions`,
      },
    },
  },
});

const project1Studies: Study[] = [
  generateStudy({
    id: 1,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS',
    description: '10000 batiments',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'SUCCESS',
    resultsConsumptionsState: 'RUNNING',
  }),
  generateStudy({
    id: 2,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CALIBRAGE',
    buildingModellingState: 'ACTION_REQUIRED',
    resultsBuildingModellingState: 'FUTURE',
    systemsModellingState: 'FUTURE',
    resultsConsumptionsState: 'FUTURE',
  }),
  generateStudy({
    id: 3,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS RACCORDEMENT RESEAU',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'RUNNING',
    systemsModellingState: 'FUTURE',
    resultsConsumptionsState: 'FUTURE',
  }),
  generateStudy({
    id: 4,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CORRIGE APRES POINTAGE',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'ACTION_REQUIRED',
    resultsConsumptionsState: 'FUTURE',
  }),
  generateStudy({
    id: 5,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CORRIGE APRES POINTAGE DIMENSIONNEMENT EN COURS',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'RUNNING',
    resultsConsumptionsState: 'FUTURE',
  }),
  generateStudy({
    id: 6,
    projectId: 1,
    name: 'DUPLICAT ETAT INITIAL EURALYS CORRIGE APRES POINTAGE SANS SUPRESSION',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'ACTION_REQUIRED',
    resultsConsumptionsState: 'FUTURE',
  }),
  generateStudy({
    id: 14,
    projectId: 1,
    name: 'ETAT INITIAL EURALYS CALIBRAGE avec image importée',
    buildingImageCoordinates: [
      { lat: 48.840436933131805, lng: 2.58379340171814 },
      { lat: 48.84082530159813, lng: 2.5846838951110844 },
      { lat: 48.83985084410871, lng: 2.5843834877014165 },
      { lat: 48.84023921711944, lng: 2.585273981094361 },
    ],
    buildingModellingState: 'ACTION_REQUIRED',
    resultsBuildingModellingState: 'FUTURE',
    systemsModellingState: 'FUTURE',
    resultsConsumptionsState: 'FUTURE',
  }),
];

const project2Studies: Study[] = [
  generateStudy({
    id: 7,
    projectId: 2,
    name: 'TEST VARIANTE UTILISEE PAR UN UTILISATEUR',
    description: 'version de bruz initialisé',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'ACTION_REQUIRED',
    resultsConsumptionsState: 'FUTURE',
  }),
  generateStudy({
    id: 8,
    projectId: 2,
    name: 'Essai2 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'ACTION_REQUIRED',
    resultsConsumptionsState: 'ERROR',
  }),
  generateStudy({
    id: 9,
    projectId: 2,
    name: 'Essai4 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'ACTION_REQUIRED',
    resultsConsumptionsState: 'ERROR',
  }),
  generateStudy({
    id: 10,
    projectId: 2,
    name: 'Essai5 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'RUNNING',
    systemsModellingState: 'FUTURE',
    resultsConsumptionsState: 'FUTURE',
  }),
  generateStudy({
    id: 11,
    projectId: 2,
    name: 'Essai6 sans dimensionnement',
    description:
      'Scénario 3 : Changement de technologie de panneaux pour la Halle > Monocristallin',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'ACTION_REQUIRED',
    resultsConsumptionsState: 'FUTURE',
  }),
];

const project4Studies: Study[] = [
  generateStudy({
    id: 13,
    projectId: 4,
    name: 'Une seule variante',
    buildingModellingState: 'SUCCESS',
    resultsBuildingModellingState: 'SUCCESS',
    systemsModellingState: 'SUCCESS',
    resultsConsumptionsState: 'SUCCESS',
  }),
];
/*
  Please refer to the project mock identify their rights
*/

const user1studiesIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13];
const otherStudiesIds = [12];

const availableStudy = {
  isCurrentlyUsed: false,
};

const notAvailableStudy = {
  isCurrentlyUsed: true,
  usedBy: 'foo.bar@efficacity.com',
};

const checkStudiesAccess = (studyId: number) => {
  if (![...user1studiesIds, ...otherStudiesIds].includes(studyId)) throw new NotFoundError();
  if (!user1studiesIds.includes(studyId)) throw new ForbiddenAccessError();
};
export class StudyApiMock implements StudyApi {
  async createStudy(projectId: number, studyData: StudyForm): Promise<number> {
    checkProjectsAccess(projectId);
    if (studyData.name!.length === 0) throw InvalidInputError;
    return 10;
  }

  async getProjectStudies(projectId: number): Promise<Study[]> {
    checkProjectsAccess(projectId);
    if (projectId === 1) return project1Studies;
    else if (projectId === 2) return project2Studies;
    else if (projectId === 4) return project4Studies;
    else return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async updateStudy(studyId: number, studyData: StudyForm): Promise<number> {
    checkStudiesAccess(studyId);
    return 1;
  }

  async duplicateStudy(
    studyId: number,
    newStudyData: StudyForm,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resultsToDuplicate: ResultType,
  ): Promise<Study> {
    checkStudiesAccess(studyId);
    if (newStudyData.name!.length === 0) throw new InvalidInputError();
    return project1Studies[0];
  }

  async deleteStudy(studyId: number): Promise<void> {
    checkStudiesAccess(studyId);
  }

  async getCurrentUseState(studyId: number): Promise<CurrentUseState> {
    if (studyId === 7) return notAvailableStudy;
    return availableStudy;
  }

  getStudyById(studyId: number): Study {
    const foundStudy = [...project1Studies, ...project2Studies, ...project4Studies].find(
      (study) => study.id === studyId,
    );

    if (!foundStudy) throw new Error('study not found within projects');
    return foundStudy;
  }

  async getStudy(studyId: number): Promise<Study> {
    return this.getStudyById(studyId);
  }

  async getStudyInfo(studyId: number): Promise<StudyInfo> {
    const study = this.getStudyById(studyId);

    return {
      id: studyId,
      projectId: study.projectId,
      name: study.name,
      description: study.description,
      building_image_coordinates: study.building_image_coordinates,
      network_image_coordinates: study.network_image_coordinates,
      savedAt: new Date(),
    };
  }

  async importBuildings(importPerimeter: ClosedRing): Promise<ImportedBuilding[]> {
    await fakeApiCallDelay(3000);
    const rawPolygons = tesselate(polygon([importPerimeter.map(({ lat, lng }) => [lng, lat])]));
    const importedBuildings: ImportedBuilding[] = rawPolygons.features.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (feature: any) => ({
        externalId: `BATIMENT0000${Math.floor(Math.random() * (20000 - 10000 + 1)) + 10000}`,
        geometry: geoJSONCoordinatesToPolygonGeometry(
          transformScale(feature.geometry, 0.9).coordinates,
        ),
        properties: {
          altitude: 94,
          floor_count: 2,
          mean_floor_height: 2.7,
          performance: performanceOptions[Math.floor(Math.random() * performanceOptions.length)],
          usage_zone_code: usageCodeOptions[Math.floor(Math.random() * usageCodeOptions.length)],
          year: 2015,
        },
      }),
    );
    return importedBuildings;
  }

  async startSimulation(
    ignoredStudyId: number,
    ignoredSimulationType: StudySimulationsType,
    // eslint-disable-next-line no-empty-function
  ): Promise<void> {}

  async deleteSimulationResults(
    ignoredStudyId: number,
    ignoredSimulationType: StudySimulationsType,
    // eslint-disable-next-line no-empty-function
  ): Promise<void> {}

  async stopSimulation(
    ignoredStudyId: number,
    ignoredSimulationType: StudySimulationsType,
  ): Promise<void> {
    await fakeApiCallDelay(2000);
  }
}
