import type { User } from '@/api/user';
// eslint-disable-next-line no-restricted-imports
import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

export function initSentry(app: App, router: Router, user: User) {
  const { VITE_SENTRY_PROJECT_DSN, VITE_SENTRY_ENV, VITE_SENTRY_RELEASE } = import.meta.env;

  Sentry.setUser({ id: user.id });

  Sentry.init({
    app,
    dsn: VITE_SENTRY_PROJECT_DSN,
    environment: VITE_SENTRY_ENV,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    release: VITE_SENTRY_RELEASE,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    tracesSampleRate: 1.0,
  });
}
