<script lang="ts" setup>
import { HeaderAndMenu } from '@efficacity/components-library-vuejs3';
import '@efficacity/components-library-vuejs3/style.css';
import { useApi } from '@/api';
import LangSelector from '@/components/LangSelector.vue';
import { isRtl, useI18n } from '@/plugins/i18n';
import keycloak from '@/plugins/keycloak';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const { currentRoute } = useRouter();

const login = ref<string>();

async function getLogin() {
  login.value = (await useApi().currentUser).login;
}

getLogin();
</script>

<template>
  <v-app full-height>
    <v-locale-provider :rtl="isRtl()">
      <v-layout>
        <HeaderAndMenu>
          <template #title>
            <router-link to="/">
              <VImg
                height="40"
                src="/PowerDIS_logo.svg"
                width="150"
              />
            </router-link>
          </template>

          <template #breadcrumb>
            <div id="app-breadcrumb">
              <!-- <Teleport> target -->
            </div>
          </template>

          <template #actions>
            <div>{{ login }}</div>
            <v-tooltip :text="t('logout')">
              <template #activator="{ props }">
                <button
                  v-bind="props"
                  @click="keycloak.logout"
                >
                  <v-icon icon="mdi-logout" />
                </button>
              </template>
            </v-tooltip>
          </template>

          <template #menuList>
            <ul>
              <li>
                <router-link to="/">{{ t('menu.home') }}</router-link>
              </li>
            </ul>

            <div id="app-menu-content">
              <!-- <Teleport> target -->
            </div>
          </template>

          <template #menuInfos>
            <LangSelector />

            <div id="app-menu-bottom-info">
              <!-- <Teleport> target -->
            </div>

            <a :href="t('global.config.documentationUrl')">
              {{ t('menu.documentation') }}
            </a>

            <p>
              {{ t('menu.contactUsInCaseOfIssue') }}
              {{ t('global.config.assistanceMailAddress') }}
            </p>
          </template>
        </HeaderAndMenu>

        <v-main scrollable>
          <!-- :key forces re-rendering the view component when the route changes (even if the
          target component doesn't) -->
          <router-view :key="currentRoute.fullPath" />
        </v-main>
      </v-layout>
    </v-locale-provider>
  </v-app>
</template>

<i18n lang="json">
{
  "fr": {
    "logout": "Se déconnecter",
    "menu": {
      "home": "Accueil",
      "contactUsInCaseOfIssue": "Si vous rencontrez un problème, contactez-nous : ",
      "documentation": "Aide et documentation"
    }
  },
  "en": {
    "logout": "Log out",
    "menu": {
      "home": "Home",
      "contactUsInCaseOfIssue": "If you encounter an issue, contact us: ",
      "documentation": "Help and user manual"
    }
  }
}
</i18n>

<style lang="scss">
html {
  overflow: hidden;
  font-size: 16px;
  font-family: 'Lato', Arial, sans-serif;
}

.v-tooltip {
  white-space: pre-line;
}

.Vue-Toastification__container {
  &.bottom-center {
    inset-block-end: 0 !important;
  }

  .Vue-Toastification__toast {
    align-items: center;
    min-height: 50px;
    padding: 10px 20px;

    &.Vue-Toastification__toast--info {
      &:not(.outlined) {
        @include backgroundAndColor('info');
      }

      &.outlined {
        @include color('info');
      }
    }

    &.Vue-Toastification__toast--success {
      &:not(.outlined) {
        @include backgroundAndColor('success');
      }

      &.outlined {
        @include color('success');
      }
    }

    &.Vue-Toastification__toast--warning {
      &:not(.outlined) {
        @include backgroundAndColor('warning');
      }

      &.outlined {
        @include color('warning');
      }
    }

    &.Vue-Toastification__toast--error {
      &:not(.outlined) {
        @include backgroundAndColor('error');
      }

      &.outlined {
        @include color('error');
      }
    }

    &.outlined {
      border: 2px solid currentColor;
      @include background('surface');
    }

    .Vue-Toastification__toast-body,
    .Vue-Toastification__toast-component-body {
      line-height: 1.2;
    }

    .Vue-Toastification__close-button {
      color: currentColor;
    }
  }
}

#app {
  #app-breadcrumb {
    position: relative;
    padding-inline-start: 34px;
  }

  .v-main {
    @include backgroundAndColor('background');
  }

  // change aspect of disabled fields globally
  .section .v-field--disabled {
    @include color('primary');
    opacity: 1;
  }
  .section .v-input--disabled .v-field__input {
    @include background('primary', 0.24);
  }

  // fix issues with vuetify layout visible because of our customisation above
  .v-autocomplete,
  .v-select,
  .v-combobox {
    .v-field {
      padding-inline-end: 0px;
    }
    .v-field__append-inner .v-icon {
      margin-inline: 6px;
    }
    .v-field__prepend-inner .v-icon {
      margin-inline-end: 10px;
    }
  }

  h1,
  h2,
  h3 {
    margin-block-end: 16px;
  }

  .enforce-min-page-width {
    min-width: 1250px;
  }

  // #region Leaflet
  .leaflet-container {
    font-family: inherit;
  }

  // controls buttons & corner infos
  .leaflet-top {
    margin-top: 10px;
  }

  .leaflet-bottom.leaflet-left {
    margin-bottom: 10px;
  }
  // #endregion
}
</style>
