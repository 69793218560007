import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { isRtl } from '@/plugins/i18n';

const timeToReadOneLetter = 60;
const toastMinDuration = 3000;
const toastMaxDuration = 7000;

const toastOptions = {
  hideProgressBar: true,
  position: POSITION.BOTTOM_CENTER,
  timeout: 0, // duration is set dynamically below
  rtl: isRtl(),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterBeforeCreate: (toast: any) => {
    const { content, timeout } = toast;

    // adjust toast duration based on its content
    if (timeout === 0 && typeof content === 'string') {
      // eslint-disable-next-line no-param-reassign
      toast.timeout = Math.min(
        Math.max(content.length * timeToReadOneLetter, toastMinDuration),
        toastMaxDuration,
      );
    }

    return toast;
  },
};

export { Toast, toastOptions };
