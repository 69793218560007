import { customIcons } from '@/components/icons/customIcons';
import { powerDisTheme } from '@efficacity/components-library-vuejs3';
import { mdi } from 'vuetify/iconsets/mdi';
import { en, fr } from 'vuetify/locale';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import {
  createVuetify as initializeVuetify,
  type LocaleMessages,
  type VuetifyOptions,
} from 'vuetify';

export function createVuetify(locale: string) {
  const buildLocaleSettings: () => VuetifyOptions['locale'] = () => {
    if (locale === 'en') {
      const messages = { en } as LocaleMessages;
      return { locale: 'en', fallback: 'en', messages };
    } else if (locale === 'fr' || locale === 'test') {
      const messages = { fr } as LocaleMessages;
      return { locale: 'fr', fallback: 'en', messages };
    } else {
      throw new Error('unknown locale');
    }
  };

  return initializeVuetify({
    locale: buildLocaleSettings(),
    theme: {
      defaultTheme: 'powerDisTheme',
      themes: {
        powerDisTheme: {
          variables: { ...powerDisTheme.variables },
          colors: {
            ...powerDisTheme.colors,
            'stepper-success': '#64bb6a',
            'stepper-error': '#e95c50',
            'stepper-action-required': '#5c75a4',
            'stepper-running': '#f5a623',
            'stepper-future': '#7f7f7f',
            'edited-layer': '#ffc300',
            'on-edited-layer': '#ffffff',
          },
        },
      },
    },
    icons: {
      defaultSet: 'mdi',
      sets: {
        mdi,
        custom: customIcons,
      },
    },
  });
}
