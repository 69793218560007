// TODO: ESLint rule to tune globally
/* eslint-disable no-empty-function */

import type { ClosedRing } from '@/components/soon-shared/util';
import {
  geoJSONCoordinatesToPolygonGeometry,
  polygonGeometryToGeoJSONCoordinates,
} from '@/util/geography';
import { AxiosError } from 'axios';
import {
  ResultsToDuplicate,
  type BusySharedStudyPost200ResponseOneOf,
  type DefaultApi,
  type ErrorResponse,
  type ProjectProjectIdStudiesGet200ResponseOneOf,
  type ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation,
  type SimpleStatusResponse,
  type SimulationDeleteDelete200ResponseOneOf,
  type SimulationGet200ResponseOneOf,
  type StudyImportBuildingsPost200ResponseOneOf,
  type StudyImportBuildingsPost200ResponseOneOfData,
  type StudyStudyIdGet200ResponseOneOf,
  type Study as StudyTypeApi,
} from '../api-client';
import { ForbiddenAccessError, InvalidResponseError, NotFoundError } from '../errors';
import type { CurrentUseState, ResultType } from '../types';
import type { User } from '../user';
import { convertIfParseError, safeParseDate } from '../util';
import type {
  ImportedBuilding,
  Study,
  StudyApi,
  StudyForm,
  StudyInfo,
  StudySimulationsType,
  StudyStateByStep,
} from './study.interface';

export class TooManyBuildingsToImportError extends Error {}

export class StudyApiFacade implements StudyApi {
  constructor(
    private apiClient: DefaultApi,
    private currentUser: Promise<User>,
  ) {}

  async createStudy(projectId: number, studyData: StudyForm): Promise<number> {
    const userId = (await this.currentUser).id;
    const response = await this.apiClient.studyPost({
      user_id: userId,
      project: projectId,
      name: studyData.name!,
      description: studyData.description!,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }

    const rawNewStudy = response.data.data as Study;

    return rawNewStudy.id;
  }

  async getStudyInfo(studyId: number): Promise<StudyInfo> {
    try {
      const response = await this.apiClient.studyStudyIdGet(studyId);
      const rawStudy = (response.data as StudyStudyIdGet200ResponseOneOf).data!;

      return {
        id: rawStudy.id!,
        projectId: rawStudy.project!,
        name: rawStudy.name!,
        description: rawStudy.description!,
        building_image_coordinates: rawStudy.building_image_coordinates
          ? JSON.parse(rawStudy.building_image_coordinates)
          : [],
        network_image_coordinates: rawStudy.network_image_coordinates
          ? JSON.parse(rawStudy.network_image_coordinates)
          : [],
        savedAt: safeParseDate(rawStudy.last_save_time!),
      };
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.status === 403) {
        // Api return a 403 when id is not own by the user (existing or not)
        // we catch it and return NotFoundError to better response management.
        throw new NotFoundError();
      } else {
        throw new InvalidResponseError();
      }
    }
  }

  async getStudyState(studyId: number) {
    const simulationInfoResponse = await this.apiClient.simulationGet(studyId);

    if (simulationInfoResponse.data.data?.code === 1) {
      throw new NotFoundError();
    }

    const rawSimulationInfo: SimulationGet200ResponseOneOf = simulationInfoResponse.data;

    return {
      statesByStep: this.getStatesBySteps(rawSimulationInfo.data!, studyId),
    };
  }

  async getStudy(studyId: number): Promise<Study> {
    const studyInfoResponse = await this.getStudyInfo(studyId);

    const state = await this.getStudyState(studyId);

    return {
      ...studyInfoResponse,
      state,
    };
  }

  private getStatesBySteps(
    rawSimulationInfos: ProjectProjectIdStudiesGet200ResponseOneOfDataInnerAllOfSimulation,
    studyId: number,
  ): StudyStateByStep {
    const { demand, sizing, systems } = rawSimulationInfos;

    // Initial state when no simulation have run yet
    const steps: StudyStateByStep = {
      buildingModelling: { state: 'ACTION_REQUIRED', url: '' },
      resultsBuildingModelling: { state: 'FUTURE', url: '' },
      systemsModelling: { state: 'FUTURE', url: '' },
      resultsConsumptions: { state: 'FUTURE', url: '' },
    };
    // Utilities to decode raw step statuses
    const isRunning = (rawStep: { status?: string }) =>
      rawStep.status === 'Launched' ||
      rawStep.status === 'Running' ||
      rawStep.status === 'Checking' ||
      rawStep.status === 'Pending';
    const isDone = (rawStep: { status?: string }) => rawStep.status === 'Done';
    const isFailed = (rawStep: { status?: string }) => rawStep.status === 'Failed';
    const isAborted = (rawStep: { status?: string }) => rawStep.status === 'Aborted';

    // Decode step by step. Raw simulation states gives a hint on the edition step
    // before and after.
    if (systems) {
      if (!demand || !isDone(demand)) {
        throw new InvalidResponseError('Demand must exist and be done');
      } else if (sizing && !isDone(sizing)) {
        throw new InvalidResponseError('If sizing exists, it should be done');
      } else {
        steps.buildingModelling.state = 'SUCCESS';
        steps.resultsBuildingModelling.state = 'SUCCESS';

        if (isRunning(systems)) {
          steps.systemsModelling.state = 'SUCCESS';
          steps.resultsConsumptions.state = 'RUNNING';
        } else if (isDone(systems)) {
          steps.systemsModelling.state = 'SUCCESS';
          steps.resultsConsumptions.state = 'SUCCESS';
        } else if (isAborted(systems)) {
          steps.systemsModelling.state = 'ACTION_REQUIRED';
          steps.resultsConsumptions.state = 'FUTURE';
        } else if (isFailed(systems)) {
          steps.systemsModelling.state = 'ACTION_REQUIRED';
          steps.resultsConsumptions.state = 'ERROR';
        } else {
          throw new InvalidResponseError('Invalid system simulation status');
        }
      }
    } else if (sizing) {
      if (!demand || !isDone(demand)) {
        throw new InvalidResponseError('Demand must exist and be done');
      } else {
        steps.buildingModelling.state = 'SUCCESS';
        steps.resultsBuildingModelling.state = 'SUCCESS';

        if (isRunning(sizing)) {
          steps.systemsModelling.state = 'RUNNING';
          steps.resultsConsumptions.state = 'FUTURE';
        } else if (isDone(sizing) || isAborted(sizing) || isFailed(sizing)) {
          steps.systemsModelling.state = 'ACTION_REQUIRED';
          steps.resultsConsumptions.state = 'FUTURE';
        } else {
          throw new InvalidResponseError('Invalid sizing simulation status');
        }
      }
    } else if (demand) {
      steps.resultsConsumptions.state = 'FUTURE';

      if (isRunning(demand)) {
        steps.buildingModelling.state = 'SUCCESS';
        steps.resultsBuildingModelling.state = 'RUNNING';
        steps.systemsModelling.state = 'FUTURE';
      } else if (isDone(demand)) {
        steps.buildingModelling.state = 'SUCCESS';
        steps.resultsBuildingModelling.state = 'SUCCESS';
        steps.systemsModelling.state = 'ACTION_REQUIRED';
      } else if (isAborted(demand)) {
        steps.buildingModelling.state = 'ACTION_REQUIRED';
        steps.resultsBuildingModelling.state = 'FUTURE';
        steps.systemsModelling.state = 'FUTURE';
      } else if (isFailed(demand)) {
        steps.buildingModelling.state = 'ACTION_REQUIRED';
        steps.resultsBuildingModelling.state = 'ERROR';
        steps.systemsModelling.state = 'FUTURE';
      } else {
        throw new InvalidResponseError('Invalid demand simulation status');
      }
    } else {
      steps.buildingModelling.state = 'ACTION_REQUIRED';
      steps.resultsBuildingModelling.state = 'FUTURE';
      steps.systemsModelling.state = 'FUTURE';
      steps.resultsConsumptions.state = 'FUTURE';
    }

    return this.getStepsWithUrls(studyId, steps);
  }

  // eslint-disable-next-line class-methods-use-this
  private getStepsWithUrls(studyId: number, steps: StudyStateByStep): StudyStateByStep {
    const baseStudyRoute = `/study/${studyId}/`;

    const paths = {
      studyDemands: 'buildings-modelling',
      resultsDemands: 'results-demands',
      studySystems: 'study-systems',
      resultsConsumptions: 'results-consumptions',
    };

    const stepsCopy = { ...steps };

    Object.keys(stepsCopy).forEach((stepName) => {
      if (stepName === 'buildingModelling')
        stepsCopy[stepName]!.url = baseStudyRoute + paths.studyDemands;
      else if (stepName === 'resultsBuildingModelling')
        stepsCopy[stepName]!.url = baseStudyRoute + paths.resultsDemands;
      else if (stepName === 'systemsModelling')
        stepsCopy[stepName]!.url = baseStudyRoute + paths.studySystems;
      else if (stepName === 'resultsConsumptions')
        stepsCopy[stepName]!.url = baseStudyRoute + paths.resultsConsumptions;
    });

    return stepsCopy;
  }

  async getProjectStudies(projectId: number): Promise<Study[]> {
    const response = await this.apiClient.projectProjectIdStudiesGet(projectId);

    if (response.data.status === false) {
      // Our user ID comes from the authentication mechanism, and the authentication has worked
      // at that point. Therefore if p10-backend fails with user ID not found, it actually means
      // there is an authorization issue.
      throw new NotFoundError();
    }

    const rawStudies = (response.data as ProjectProjectIdStudiesGet200ResponseOneOf).data!;

    try {
      return rawStudies.map((rawStudy) => ({
        id: rawStudy.id!,
        projectId: rawStudy.project!,
        name: rawStudy.name!,
        description: rawStudy.description!,
        building_image_coordinates: rawStudy.building_image_coordinates
          ? JSON.parse(rawStudy.building_image_coordinates)
          : [],
        network_image_coordinates: rawStudy.network_image_coordinates
          ? JSON.parse(rawStudy.network_image_coordinates)
          : [],
        savedAt: safeParseDate(rawStudy.last_save_time!),
        state: {
          statesByStep: this.getStatesBySteps(rawStudy.simulation!, rawStudy.id!),
        },
      }));
    } catch (error) {
      throw convertIfParseError(error);
    }
  }

  async updateStudy(studyId: number, studyData: StudyForm): Promise<number> {
    const response = await this.apiClient.studyStudyIdUpdatePut(studyId, {
      name: studyData.name,
      description: studyData.description,
      building_image_coordinates: JSON.stringify(studyData.building_image_coordinates),
      network_image_coordinates: JSON.stringify(studyData.network_image_coordinates),
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }

    const rawStudy = (response.data as StudyStudyIdGet200ResponseOneOf).data!;
    return rawStudy.project!;
  }

  async duplicateStudy(
    studyIdToDuplicate: number,
    newStudyData: StudyForm,
    resultsTypesToDuplicate: ResultType,
  ): Promise<Study> {
    let requestResultsTypesToDuplicate: ResultsToDuplicate =
      ResultsToDuplicate.RightSquareBracket;
    if (resultsTypesToDuplicate === 'DEMANDS')
      requestResultsTypesToDuplicate = ResultsToDuplicate.Demand;
    else if (resultsTypesToDuplicate === 'DEMANDS_AND_CONSUMPTIONS') {
      requestResultsTypesToDuplicate = ResultsToDuplicate.Demandsystems;
    }
    const response = await this.apiClient.studyStudyIdDuplicatePost(studyIdToDuplicate, {
      name: newStudyData.name!,
      description: newStudyData.description!,
      results: requestResultsTypesToDuplicate,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }

    const rawStudy = response.data.data! as StudyTypeApi;

    const state = await this.getStudyState(rawStudy.id!);

    return {
      id: rawStudy.id!,
      projectId: rawStudy.project!,
      name: rawStudy.name!,
      description: rawStudy.description!,
      building_image_coordinates: rawStudy.building_image_coordinates
        ? JSON.parse(rawStudy.building_image_coordinates)
        : [],
      network_image_coordinates: rawStudy.network_image_coordinates
        ? JSON.parse(rawStudy.network_image_coordinates)
        : [],
      savedAt: safeParseDate(rawStudy.last_save_time!),
      state,
    };
  }

  async deleteStudy(studyId: number): Promise<void> {
    const response = await this.apiClient.studyStudyIdDeleteDelete(studyId);

    if (response.data.status === false) {
      if ((response.data as ErrorResponse).message!.includes('one of its simulation is running'))
        throw new ForbiddenAccessError();
      throw new InvalidResponseError();
    }
  }

  async getCurrentUseState(studyId: number): Promise<CurrentUseState> {
    const userId = (await this.currentUser).id;
    const response = await this.apiClient.busySharedStudyPost({
      study_id: studyId,
      user_id: userId,
    });

    if (response.data.status === false) {
      throw new NotFoundError();
    }

    const rawData = (response.data as BusySharedStudyPost200ResponseOneOf).data!;

    return {
      isCurrentlyUsed: rawData.locked!,
      ...(rawData.locked && { usedBy: rawData.user }),
    };
  }

  async importBuildings(importPerimeter: ClosedRing): Promise<ImportedBuilding[]> {
    const response = await this.apiClient.studyImportBuildingsPost({
      geometry: {
        type: 'Polygon',
        coordinates: polygonGeometryToGeoJSONCoordinates([importPerimeter]),
      },
    });

    if (response.data.status === false) {
      const errorResponse = response.data as StudyImportBuildingsPost200ResponseOneOfData;
      if (errorResponse.message!.includes('La zone demandée contient plus de')) {
        throw new TooManyBuildingsToImportError();
      } else {
        throw new InvalidResponseError();
      }
    }

    const rawImportedBuildings = response.data as StudyImportBuildingsPost200ResponseOneOf;

    if (rawImportedBuildings === undefined) {
      throw new InvalidResponseError();
    }

    const importedBuildings: ImportedBuilding[] =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rawImportedBuildings.data!.zone_import!.features!.map((feature: any) => ({
        externalId: feature.id,
        properties: feature.properties,
        geometry: geoJSONCoordinatesToPolygonGeometry(feature.geometry.coordinates),
      }));

    return importedBuildings;
  }

  async startSimulation(studyId: number, simulationType: StudySimulationsType): Promise<void> {
    const userId = (await this.currentUser).id;

    const response = await this.apiClient.simulationCreatePost({
      user_id: userId,
      study_id: studyId,
      simulation_type: simulationType,
    });

    if (response.data.status === false) {
      throw new InvalidResponseError();
    }
  }

  async deleteSimulationResults(
    studyId: number,
    simulationType: StudySimulationsType,
  ): Promise<void> {
    const userId = (await this.currentUser).id;

    const response = await this.apiClient.simulationDeleteDelete({
      user_id: userId,
      study_id: studyId,
      simulation_type: simulationType,
    });

    // Beware, the API returns `{status: true}` on succes but not status false on error
    // eg. `{"data":{"code":2,"message":"Simulation matching query does not exist."}}`
    if (
      (response.data as SimpleStatusResponse)?.status === false ||
      (response.data as SimulationDeleteDelete200ResponseOneOf)?.is_valid === false
    ) {
      throw new InvalidResponseError();
    }
  }

  async stopSimulation(studyId: number, simulationType: StudySimulationsType): Promise<void> {
    await this.apiClient.simulationStopDelete({
      study_id: studyId,
      simulation_type: simulationType,
    });
    // Beware, the API returns `{status: true}` on succes but not status false on error
    // eg. `{"data":{"code":2,"message":"Simulation matching query does not exist."}}`

    // Workaround
    // Dimosim kill function is bugged
    // API not updating correctly a running in Dimosim simulation status because of incorrect response when killed.
    // API updating correctly when simulation is pending, or already dead.
    // First call return incorrect response
    // Response not checked

    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    await this.apiClient.simulationStopDelete({
      study_id: studyId,
      simulation_type: simulationType,
    });
  }
}
